
@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#modal {
	position: absolute;
	top: 0;
	left: 0;
    text-align: left;
}

.modal__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(56, 49, 40, 0.5);
    z-index: 100;
    overflow-y: auto;
}

.modal__overflow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 120px 0;
    width: 100%;
    min-height: 100%;
}

.modal__container {
    background-color: white;
    border-radius: 24px;
    max-width: 937px;
    width: calc(100% - 32px);
    box-shadow: var(--shadow);
}

.modal__top-line {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--primary50);
}

.modal__title {
    flex: 1;
    text-align: center;
    padding-left: 44px;
}

.modal__close-btn {
    padding: 12px;
    background: white;
}

.modal__close-btn svg {
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
}

.modal__content {
    padding: 24px;
}

.is-mobile .modal__overflow {
    padding: 60px 0;
}

.is-mobile .modal__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;
    width: auto;
    border-radius: 0;
    display: flex;
    flex-direction: column;
}

.is-mobile .modal__content {
    padding: 24px 16px;
    height: 100%;
    overflow-y: auto;
}