.app-body.theme-dark {
  background-color: var(--primary);
  color: white;
}

.app-body.theme-light {
  background-color: white;
  color: var(--primary);
}

