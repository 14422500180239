.custom-dropdown {
  position: relative;
}

.custom-dropdown__btn,
.custom-dropdown__menu {
  background: white;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--primary);
  text-align: left;
}

.custom-dropdown__btn {
  display: flex;
  padding: 7px 19px;
  align-items: center;
  border-radius: 60px;
  border: 1px solid var(--primary90);
}

.custom-dropdown__value {
  display: flex;
  flex-direction: column;
  user-select: none;
  pointer-events: none;
}

.custom-dropdown__label {
  font-weight: 700;
  color: var(--primary70);
  margin-bottom: -2px;
}

.custom-dropdown__prefix {
  margin-right: 4px;
  user-select: none;
  pointer-events: none;
}

.custom-dropdown__chevron {
  margin-left: 8px;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-dropdown__btn[aria-expanded="true"] .custom-dropdown__chevron {
  transform: scaleY(-1);
}

.custom-dropdown__menu {
  font-size: 0.875rem;
  line-height: 1.25;
  opacity: 0;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  visibility: hidden;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(6px);
  will-change: transform;
  list-style: none;
  position: absolute;
  width: max-content;
  min-width: 100%;
  top: calc(100% + 12px);
  overflow: hidden;
  z-index: 2;
  max-height: 255px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-dropdown__menu::-webkit-scrollbar {
  display: none;
}

.custom-dropdown__menu--visible {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.custom-dropdown__item {
  display: block;
  padding: 11px 16px;
  cursor: pointer;
}

.custom-dropdown__item:hover {
  background-color: var(--primary50);
}

.custom-dropdown__item.custom-dropdown__item--active {
  background-color: var(--secondary);
}

.custom-dropdown svg {
  user-select: none;
  pointer-events: none;
}