.user-menu {
    width: 402px;
    border: 1px solid var(--primary60);
    border-radius: 24px;
}

.user-menu__item:not(:last-of-type) .user-menu__link {
    border-bottom: 1px solid var(--primary60)
}

.user-menu__item:first-of-type .user-menu__link {
    border-radius: 24px 24px 0 0;
}

.user-menu__item:last-of-type .user-menu__link {
    border-radius: 0 0 24px 24px;
}

.user-menu__link {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 16px 80px;
}

.user-menu__link .h4 {
    margin-bottom: 4px;
}

.user-menu__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 24px;
    width: 40px;
    height: 40px;
    background-color: var(--primary60);
    border-radius: 50%;
}

.user-menu__icon {
    width: 24px;
    height: 24px;
}

.is-mobile .user-menu {
    width: 100%;
}