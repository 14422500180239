.charger-details__title {
  margin-bottom: 16px;
}

.charger-details__info-box {
  background: var(--primary50);
  border-radius: 24px;
  padding: 16px;
}

.charger-details__info-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.charger-details__info-item {
  display: flex;
  align-items: flex-start;
}

.charger-details__info-item:nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}

.charger-details__info-item:nth-child(2) {
  grid-area: 2 / 1 / 3 / 2;
}

.charger-details__info-item:nth-child(3) {
  grid-area: 3 / 1 / 4 / 2;
}

.charger-details__info-item:nth-child(4) {
  grid-area: 1 / 2 / 2 / 3;
}

.charger-details__info-item:nth-child(5) {
  grid-area: 2 / 2 / 3 / 3;
}

.charger-details__info-item:nth-child(6) {
  grid-area: 3 / 2 / 4 / 3;
}

.charger-details__info-item .link {
  padding: 0;
  line-height: 1.5;
}

.charger-details__info-item svg {
  margin-top: 2px;
}

.charger-details__info-group {
    margin-top: 40px;
}

.charger-details__connector-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px -20px;
}

.charger-details__connector-loader {
  height: 128px;
  width: 100%;
}

.charger-evse {
  padding: 0 24px;
  width: 50%;
}

.charger-evse:nth-of-type(odd) {
  border-right: 1px solid var(--primary50);
}

.charger-evse {
  margin-bottom: 20px;
}

.charger-connector {
  display: flex;
}

.charger-connector__info-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 13px;
}

.charger-connector__icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  flex-shrink: 0;
  width: 86px;
  height: 86px;
  z-index: 1;
}

.charger-connector__icon {
  display: flex;
  width: 70px;
  height: 70px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: white;
  border-radius: 16px;
}

.charger-connector__icon.unknown {
  opacity: 0.25;
}

.charger-connector__icon svg {
  width: 40px;
  height: 40px;
}

.dashed-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 86px;
  z-index: -1;
}

.charger-connector__availability {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 7px;
}

.charger-connector__indicator {
  width: 8px;
  height: 8px;
  margin: 1px;
  border-radius: 50%;
}

.charger-connector__indicator.green {
  background-color: var(--tertiary);
}

.charger-connector__indicator.red {
  background-color: var(--red);
}

.charger-connector__indicator.grey {
  background-color: var(--primary);
  opacity: 0.25;
}

.charger-details__subtitle {
  margin-bottom: 16px;
}

.charger-details__cards-list {
  column-count: 3;
  column-gap: 16px;
  margin-bottom: 24px;
}

.charger-connector__info:not(:last-of-type) {
  margin-bottom: 4px;
}

.charger-connector__info:first-of-type {
  font-weight: bold;
}

.charger-connector__info span {
  white-space: nowrap;
}

.charger-details__info-warning {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  padding: 11px 12px;
  background-color: var(--warning);
  border-radius: 8px;
  font-weight: 500;
}

.charger-details__info-warning p {
  margin-left: 8px;
}

.availability-chart {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.availability-chart__header {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.availability-chart__header .charger-details__title {
  margin-bottom: 4px;
}

.availability-chart__header .custom-dropdown {
  margin-left: 16px;
}

.availability-chart__container {
  display: flex;
  width: 100%;
}

.availability-chart__scale {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 25px;
  padding-right: 5px;
  color: var(--primary80);
  border-right: 1px solid var(--primary60);
}

.availability-chart__overflow {
  overflow-x: auto;
  width: 100%;
}

.availability-chart__chart {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid var(--primary60);
}

.availability-chart path {
  transition: 0.7s;
}

.availability-chart__labels {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  margin-top: 8px;
  color: var(--primary80);
}

.availability-chart__overlay-container {
  position: relative;
  width: 100%;
}

.availability-chart__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
}

.availability-chart__overlay-indicator {
  position: absolute;
  padding: 4px 8px;
  border-radius: 32px;
  background-color: var(--primary);
  color: white;
  transform: translate(-50%, -34px);
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: max-content;
}

.availability-chart__overlay-indicator::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 4px 0 4px;
  border-color: var(--primary) transparent transparent transparent;
}

.availability-chart__overlay-section {
  height: 100%;
  flex: 0 1 100%;
}

.is-mobile .charger-details__info-list {
  grid-template-columns: 1fr;
}

.is-mobile .charger-details__info-item {
  grid-area: auto;
}

.is-mobile .charger-details__cards-list {
  column-count: 1;
  column-gap: 0;
}

.is-mobile .charger-details__connector-container {
  flex-direction: column;
  margin: 0 -16px -20px;
}

.is-mobile .charger-evse {
  width: 100%;
  border: none;
}

.is-mobile .charger-connector {
  flex-direction: row;
}

.is-mobile .charger-details__connector-loader {
  height: 117px;
}

.is-mobile .charger-details__info-group {
  margin-top: 24px;
}

.is-mobile .availability-chart {
  margin: 0 -16px;
}

.is-mobile .availability-chart__header {
  padding: 0 16px;
  flex-direction: column;
  align-items: flex-start;
}

.is-mobile .availability-chart__header .custom-dropdown {
  margin: 8px 0 0 ;
}

.is-mobile .availability-chart__container {
  width: 100%;
  padding-left: 16px;
}

.is-mobile .availability-chart__scale {
  margin-bottom: 41px;
}

.is-mobile .availability-chart__overlay-container {
  flex: 1 1 auto;
  width: 0;
}

.is-mobile .availability-chart__chart-container {
  min-width: 400px;
  padding: 0 16px 16px 0;
}

.is-mobile .availability-chart__overlay {
  display: none;
}