.address {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.address address {
    font-style: normal;
}

.address__link {
    text-decoration: underline;
}