.mobile-search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 100;
    overflow-y: auto;
}

.mobile-search__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    border-bottom: 1px solid var(--primary50);
}

.mobile-search__close {
    padding: 12px;
}

.mobile-search__body {
    flex: 1 1 100%;
    padding: 16px;
    overflow-y: auto;
}

.mobile-search__content {
    max-width: 560px;
    margin: 0 auto;
}

.mobile-search__btn {
    position: relative;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--primary50);
    text-align: left;
    background-color: white;
    transition: var(--transition);
}

.mobile-search__btn:not(:last-child) {
    margin-bottom: 16px;
}

.mobile-search__btn.active {
    background-color: var(--primary50);
}

.mobile-search__btn-label {
    display: block;
    margin-bottom: 8px;
}

.mobile-search__btn-label.error {
    color: var(--red);
}

.mobile-search__btn-value {
    display: block;
    width: 100%;
    transition: var(--transition);
    transition-property: padding;
}

.mobile-search__btn-value--placeholder {
    color: var(--primary80);
}

.mobile-search__btn.active .mobile-search__btn-value--input {
    padding: 12px 16px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid var(--primary60);
    color: var(--primary90);
}

.mobile-search .grouped-buttons__btn {
    background-color: var(--primary90);
    border-color: var(--primary90);
}

.mobile-search .grouped-buttons__btn:disabled {
    background-color: var(--primary70);
    border-color: var(--primary70);
}

.mobile-search .grouped-buttons__value {
    border: none;
}

.mobile-search .room-layout__label {
    color: inherit;
}

.mobile-search__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding: 16px 24px;
    border-top: 1px solid var(--primary50);
}

.mobile-search__footer .link {
    margin-right: auto;
}

.mobile-search__btn .error-container {
    position: relative;
    margin-top: 24px;
}

.mobile-search__btn .error-item {
    width: 100%;
}