.quote {
    font-size: 1.75rem;
    line-height: 1.5;
    font-family: 'ClashDisplay-Medium';
    letter-spacing: 0.015em;
    max-width: 944px;
    margin-left: auto;
    margin-right: auto;
}

.is-mobile .quote {
    font-size: 1.25rem;
    letter-spacing: 0.03em;
}