.user-details .content-container .content-container {
    margin: 16px 0 32px;
    padding-bottom: 0;
}

.user-field {
    padding: 16px 0;
}

.user-field:not(:first-of-type) {
    border-top: 1px solid var(--primary60);
}

.user-field.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
}

.user-field__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 8px;
}

.user-details__remove {
    margin-top: 40px;
}

.user-details__remove:disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
}
