.desktop-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 48px 64px;
  position: relative;
}

.desktop-search__desc {
  margin-bottom: 32px;
}

.desktop-search__line {
  width: 0;
  height: 44px;
  border-right: 1px solid var(--primary60);
  margin-right: -1px;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.desktop-search__container {
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  height: 80px;
  color: var(--primary);
  z-index: 1;
}

.desktop-search__container.compact {
  width: 400px;
}

.desktop-search__container:not(.compact) {
  width: 754px;
}

/* Search */

.app-body-title {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 815px;
}

.desktop-search-form-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.desktop-search-form {
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
  padding: 0;
  box-shadow: var(--shadow);
}

.desktop-search-form + .error-container {
  width: max-content;
  max-width: 800px;
}

.supplier .desktop-search-form-container {
  margin: 0;
  z-index: 11;
}

.supplier .desktop-search-form {
  position: absolute;
  top: -114px;
  left: 0;
  right: 0;
  padding: 0;
}

.supplier .desktop-search-form .input-component__label {
  width: 100%;
}

.supplier .desktop-search-form .desktop-search__input {
  flex: 0 0 50%;
  width: 0;
}

.desktop-search-option-container {
  position: relative;
}

.desktop-search-option {
  display: flex;
  flex-direction: column;
}

.desktop-search-text {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary);
  letter-spacing: 0.015em;
}

.desktop-search-subtext {
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: #a39582;
  align-items: center;
}

.desktop-search-form .short-right-border {
  width: 1px;
  background-color: var(--primary80);
}

.desktop-search-text .active {
  color: #2cc2ff;
}

.desktop-search-form input {
  border: none;
  height: inherit;
}

.desktop-search-form .guest-input input {
  cursor: pointer;
}

.desktop-search__btn {
  margin: 14px;
}

.error-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: calc(100% + 23px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.error-container.visible {
  visibility: visible;
  opacity: 1;
}

.error-container.not-visible {
  visibility: hidden;
  opacity: 0;
}

.supplier .error-container {
  top: -24px
}

.desktop-warning-icon {
  margin-right: 10px;
  height: 25px;
}


.search-input {
  width: 140px;
}

.location-input .input-component {
  cursor: pointer;
}

.input-component input {
  cursor: pointer;
  text-overflow: ellipsis
}

.input-component__wrapper input {
  padding: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
}

.desktop-search__input {
  flex: 0 0 225px;
}

.search .search__content .desktop-search__input {
  flex: 0 0 calc(100% / 3);
}

.input-component__label,
.desktop-search__field-btn {
  color: var(--primary);
  display: block;
  cursor: pointer;
  font-family: 'ClashDisplay-SemiBold';
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5;
  padding: 16px 24px;
  border-radius: 100px;
  width: 100%;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.desktop-search__field-btn {
  text-align: left;
}

.desktop-search__field-value {
  display: block;
  font-family: 'Roboto';
}

.desktop-search__field-value span {
  color: var(--primary80);
}

.compact .desktop-search__input {
  flex: 0 0 320px;
}

.compact .desktop-search__input .input-component__label {
  width: 320px;
}

.input-component__label span {
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.input-component__label-text {
  display: block;
}

.input-component__label-placeholder {
  max-height: 48px;
  display: inline-flex;
  max-width: 100%;
}

.input-component__label-placeholder--grey {
  color: var(--primary80);
}

.desktop-search__input.error .input-component__label-placeholder,
.desktop-search__input.error .input-component__label-text {
  color: var(--red);
}

section:not(.supplier) .desktop-search-form:not(.focused) .desktop-search__line {
  height: 32px;
}

.input-component__label.disabled {
  opacity: 0.5;
}

.desktop-search__input {
  align-self: stretch;
  display: flex;
  align-items: center;
  border-radius: 100px;
}

.desktop-search__input > div {
  max-width: 100%;
  width: 100%;
}

.desktop-search__input .suggestions-container {
  max-width: calc(100vw - 96px);
}

.desktop-search .desktop-search__input .suggestions-container {
  max-width: calc(100vw - 120px);
}

.location-search-result-container {
  overflow: hidden;
}

.desktop-search__input.focused {
  background-color: rgba(242, 236, 227, 0.5);
  box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.08);
}

.input-component input {
  display: block;
  color: var(--primary);
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.5;
  text-overflow: ellipsis;
  width: 100%;
  height: 24px;
  opacity: 1;
}

.input-component__wrapper input::placeholder {
  color: var(--primary80);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
}

.input-component input:focus {
  outline: none;
  border: none;
}

.input-component input:active {
  outline: none;
}

.blue-focused {
  color: var(--secondary90) !important;
}

.location-input .input-component input {
  cursor: text;
}

@media only screen and (max-width: 640px) {
  .app-header {
    padding-left: 10px;
  }
}

.suggestions-container {
  background-color: #ffffff;
  color: var(--primary);
  position: absolute;
  border-radius: 24px;
  padding: 24px 24px 8px;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  top: calc(100% + 8px);
  left: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: var(--shadow);
}

.location-input:focus .suggestions-container {
  visibility: visible;
  opacity: 1;
}

.suggestions-visible {
  visibility: visible;
  opacity: 1;
}

.suggestions-container--datepicker {
  padding: 0;
  overflow: hidden;
}

.suggestions-container--mood {
  padding: 0;
  width: 320px;
  white-space: normal;
  overflow: hidden;
}

.suggestions-container__footer {
  display: flex;
  max-width: none;
  padding: 16px 24px;
  margin: 0 -24px;
  border-top: 1px solid var(--primary50);
}

.modal__container .suggestions-container__footer {
  margin: 0 -24px -24px;
}

.suggestions-container--datepicker .suggestions-container__footer {
  margin: 0;
}

.suggestions-container__footer .btn {
  margin-left: 16px;
}

.suggestions-container__footer .link:first-child {
  margin-right: auto;
}

.mood-list__item.active {
  background-color: var(--secondary);
}

.mood-list__btn {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 44px;
  width: 100%;
  text-align: left;
  color: var(--primary);
}

.mood-list__btn:hover .mood-list__title {
  color: var(--secondary);
}

.mood-list__btn:hover svg {
  color: var(--secondary);
}

.mood-list__item.active .mood-list__btn:hover .mood-list__title {
  color: var(--primary);
}

.mood-list__item.active .mood-list__btn:hover svg {
  fill: var(--primary);
}

.mood-list__item:not(:last-of-type) .mood-list__btn {
  border-bottom: 1px solid var(--primary60);
}

.mood-list__title {
  color: var(--primary);
  margin-bottom: 4px;
}

.mood-list__desc {
  color: var(--primary80);
  display: block;
}

.mood-list__item.active .mood-list__desc {
  color: var(--primary);
}

.mood-list__btn svg {
  position: absolute;
  top: 16px;
  left: 16px;
}

@media only screen and (max-width: 1260px) {
  .search .search__content--full .suggestions-container--datepicker {
    left: -215px;
  }

  .home-hero .desktop-search__container:not(.compact) .suggestions-container--datepicker {
    left: -225px;
  }
}

@media only screen and (max-width: 1000px) {
  .search .suggestions-container--datepicker {
    left: -147px;
  }

  .search .search__content--full .suggestions-container--datepicker {
    left: -211px;
  }
}