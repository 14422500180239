.property-info .question {
    color: white;
}

.property-info__container {
    padding: 24px;
    border: 1px solid var(--primary60);
    border-radius: 24px;
    margin-bottom: 16px;
}

.property-info__img-container {
    position: relative;
    margin-bottom: 24px;
    border-radius: 16px;
    overflow: hidden;
    z-index: 1;
}

.property-info .card-image-slider__slide {
    padding-top: 61.28%;
}

.property-info__title {
    margin-bottom: 8px;
}

.property-info__address {
    display: inline-block;
    margin-bottom: 24px;
    text-decoration: underline;
}

.property-info__check-in-out {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
}

.property-info__check-item {
    flex: 0 0 calc(50% - 8px);
}

.property-info__check-in-out span {
    display: block;
    margin-bottom: 8px;
}

.property-info__check-in-instructions {
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 24px;
    overflow: hidden;
}

.property-info__subtitle {
    margin-bottom: 20px;
}

.property-info__pricing {
    margin-bottom: 12px;
}

.property-info__total {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--primary);
}


.property-info__label,
.property-info__price {
    flex: 50%;
    margin: 0 0 8px;
}

.property-info__price__total {
    margin-bottom: 20px;
}

.property-info__label span {
    display: block;
    margin: 4px 0;
    color: var(--primary);
}

.property-info__price {
    align-self: flex-start;
    text-align: right;
}

.property-info__list,
.property-info__instructions ul,
.property-info__special-instructions ul,
.property-info__fees ul,
.property-info__policies ul,
.property-info__cancellation-policy ul {
    margin-left: 18px;
    list-style: disc outside;
}

.property-info__important-block:not(:last-of-type) {
    margin-bottom: 24px;
}

.property-info h4 {
    margin-bottom: 8px;
}

.property-info__fees:not(:last-of-type) {
    margin-bottom: 24px;
}

.property-info__fees p:first-of-type {
    margin-bottom: 8px;
    font-family: 'ClashDisplay-Medium';
    font-size: 1rem;
}

.property-info__item,
.property-info__instructions li,
.property-info__special-instructions li,
.property-info__fees li,
.property-info__policies li,
.property-info__cancellation-policy li {
    margin-top: 4px;
    margin-bottom: 4px;
}

.property-info__block {
    margin-top: 20px;
}

.loading-property__img {
    padding-top: 51.5%;
    margin-bottom: 20px;
}

.loading-property__hotel {
    max-width: 324px;
    height: 44px;
    margin-bottom: 18px;
}

.loading-property__title {
    max-width: 324px;
    height: 28px;
    margin-bottom: 8px;
}

.loading-property__checkin {
    max-width: 324px;
    height: 48px;
    margin-bottom: 20px;
}

.loading-property__pricing {
    max-width: 324px;
    height: 280px;
    margin-bottom: 12px;
}

.loading-property__info {
    height: 670px;
}

.property-info__coc {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    margin-bottom: 16px;
}

.property-info__coc-btn {
    display: flex;
    gap: 8px;
}

.property-info__coc-btn span::first-letter {
    text-transform: capitalize;
}

.property-info__coc-price {
    margin-left: auto;
    flex-shrink: 0;
}

.property-info .search-usp {
    margin-bottom: 24px;
}

.is-mobile .property-info__container {
    padding: 16px;
}

.is-mobile .property-info__check-in-out {
    margin-bottom: 24px;
}

.is-mobile .property-info__subtitle {
    margin-bottom: 8px;
}

.is-mobile .property-info__pricing {
    margin-bottom: 16px;
}

.is-mobile .property-info .card-image-slider__slide {
    padding-top: 81%;
    padding-top: min(81%, 400px);
}