.page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: #e6fbfe;
    perspective: 300px;
}

.page-loader svg {
    width: 140px;
    height: auto;
    animation: breathe 1s infinite alternate ease-in-out;
}

@keyframes breathe {
    from {
        transform: translate3d(0, 0, -100px);
    }
    to {
        transform: translate3d(0, 0, 100px);
    }
}
