.pill-loader .loader {
  color: inherit;
}

.pill-loader.loader-container {
  margin-left: 10px;
}

.btn--blue .pill-loader .loader::before,
.btn--blue .pill-loader .loader::after {
  background: var(--secondary90);
}

.btn--outline .pill-loader .loader::before,
.btn--outline .pill-loader .loader::after {
  background: var(--primary);
}

.btn--outline:hover .pill-loader .loader::before,
.btn--outline:hover .pill-loader .loader::after {
  background: white;
}

.btn--outline-dark .pill-loader .loader::before,
.btn--outline-dark .pill-loader .loader::after {
  background: white;
}

.btn--outline-dark:hover .pill-loader .loader::before,
.btn--outline-dark:hover .pill-loader .loader::after {
  background: var(--primary);
}

.btn--grey .pill-loader .loader::before,
.btn--grey .pill-loader .loader::after {
  background: var(--primary);
}

.btn--grey .pill-loader .loader::before,
.btn--grey .pill-loader .loader::after {
  background: var(--primary);
}

.btn--grey:hover .pill-loader .loader::before,
.btn--grey:hover .pill-loader .loader::after {
  background: white;
}

.btn--green .pill-loader .loader::before,
.btn--green .pill-loader .loader::after {
  background: var(--tertiary);
}

.btn--white .pill-loader .loader::before,
.btn--white .pill-loader .loader::after {
  background: white;
}

.btn--white:hover .pill-loader .loader::before,
.btn--white:hover .pill-loader .loader::after {
  background: var(--primary);
}

.btn--red .pill-loader .loader::before,
.btn--red .pill-loader .loader::after {
  background: var(--red);
}