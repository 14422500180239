.admin-info-card {
  position: relative;
  display: flex;
  width: 100%;
  background-color: white;
  color: var(--primary);
  border-radius: 12px;
  text-align: left;
  cursor: pointer;
}

.admin-info-card.hover {
  box-shadow: 0 0 10px var(--primary70);
}

.admin-info-card__modal-btn {
  flex: 1;
  padding: 12px;
  border-right: 1px solid var(--primary50);
  border-radius: 12px 0 0 12px;
  text-align: left;
}

.admin-info-card__title {
  display: block;
}

.admin-info-card__address {
  font-style: normal;
}

.admin-info-card__select-btn {
  padding: 16px;
  border-radius: 0 12px 12px 0;
}

.admin-info-card--selected .admin-info-card__select-btn {
  background-color: var(--tertiary);
}
