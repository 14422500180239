.dialog {
  position: absolute;
  top: calc(100% + 8px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 256px;
  max-width: calc(var(--vw, 1vw) * 100 - 32px);
  padding: 16px;
  border-radius: 16px;
  background-color: white;
  box-shadow: var(--shadow);
  visibility: hidden;
  opacity: 0;
  transition: opacity var(--transition);
  z-index: 10;
}

.dialog.visible {
  opacity: 1;
  visibility: visible;
}
