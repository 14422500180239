.card {
    border-radius: 24px;
    overflow: hidden;
    color: white;
    width: calc(100% / 3 - 16px);
    margin: 0 8px 16px;
    outline-offset: 4px;
}

.card:hover .card__title {
    text-decoration: underline;
}

.card__container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card__img-container {
    position: relative;
    width: 100%;
    padding-top: 98.9%;
}

.card__content {
    flex-grow: 1;
    padding: 16px;
    background-color: var(--primary);
}

.dark-section .card__content {
    background-color: var(--primary50);
    color: var(--primary);
}

.card__header {
    display: flex;
    column-gap: 16px;
    margin-bottom: 8px;
}

.card__date {
    margin-left: auto;
}

@media only screen and (max-width: 1024px) {
    .card {
        width: calc(100% / 2 - 16px);
    }
}

@media only screen and (max-width: 767px) {
    .card {
        width: calc(100% - 16px);
    }

    .card__img-container {
        padding-top: 74%;
    }
}