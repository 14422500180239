:root {
  --primary: #383128;
  --primary90: #665E54;
  --primary80: #A39582;
  --primary70: #CDBFA4;
  --primary60: #E4D9C5;
  --primary50: #F6EEE0;
  --secondary: #2CC2FF;
  --secondary90: #06DBFF;
  --tertiary: #20F58F;
  --tertiary90: #D2FDE9;
  --red: #F53054;
  --red90: #FDD6DD;
  --accent: #FF449E;
  --warning: #FFD84C;
  --transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  --shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

/**
 * @license
 *
 * Font Family: Clash Display
 * Designed by: Indian Type Foundry
 * URL: https://www.fontshare.com/fonts/clash-display
 * © 2022 Indian Type Foundry
*/

@font-face {
  font-family: 'ClashDisplay-Medium';
  src: url('./fonts/ClashDisplay/ClashDisplay-Medium.woff2') format('woff2'),
       url('./fonts/ClashDisplay/ClashDisplay-Medium.woff') format('woff'),
       url('./fonts/ClashDisplay/ClashDisplay-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay-Semibold';
  src: url('./fonts/ClashDisplay/ClashDisplay-Semibold.woff2') format('woff2'),
       url('./fonts/ClashDisplay/ClashDisplay-Semibold.woff') format('woff'),
       url('./fonts/ClashDisplay/ClashDisplay-Semibold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay-Bold';
  src: url('./fonts/ClashDisplay/ClashDisplay-Bold.woff2') format('woff2'),
       url('./fonts/ClashDisplay/ClashDisplay-Bold.woff') format('woff'),
       url('./fonts/ClashDisplay/ClashDisplay-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}

.h1,
.h2,
.h3,
.h4,
.text-container h1,
.text-container h2,
.text-container h3,
.text-container h4,
.text-container h5,
.text-container h6 {
  line-height: 1.15;
  letter-spacing: 0.015em;
}

.text-container h1,
.text-container h2,
.text-container h3,
.text-container h4,
.text-container h5,
.text-container h6 {
  margin: 1.5rem 0;
}

.is-mobile .text-container h1,
.is-mobile .text-container h2,
.is-mobile .text-container h3,
.is-mobile .text-container h4,
.is-mobile .text-container h5,
.is-mobile .text-container h6 {
  margin: 1.4rem 0 1.6rem;
}

.h1 {
  font-size: 4rem;
  font-family: 'ClashDisplay-Bold';
}

.h2,
.privacy .text-container h1,
.text-container h2 {
  font-size: 3rem;
  font-family: 'ClashDisplay-Bold';
}

.h3,
.privacy .text-container h2,
.text-container h3 {
  font-size: 1.5rem;
  font-family: 'ClashDisplay-Semibold';
  font-weight: 600;
}

.h4,
.privacy .text-container h3,
.text-container h4,
.text-container h5,
.text-container h6 {
  font-size: 1.125rem;
  letter-spacing: 0.03em;
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
}

.h5 {
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.015em;
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
}

.is-mobile .h1,
.is-mobile .text-container h1 {
  font-size: 2.5rem;
}

.is-mobile .h2,
.is-mobile .privacy .text-container h1,
.is-mobile .text-container h2 {
  font-size: 2rem;
}

.is-mobile .h3,
.is-mobile .privacy .text-container h2,
.is-mobile .text-container h3 {
  font-size: 1.25rem;
}

.caption {
  font-size: 0.875rem;
  line-height: 1.25;
}

.cta {
  font-size: 1rem;
  line-height: 1;
  font-family: 'ClashDisplay-Semibold';
  letter-spacing: 0.015em;
}

.form-label {
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'ClashDisplay-SemiBold';
  letter-spacing: 0.015em;
}

.quote-text {
  font-size: 1.75rem;
  font-weight: 500;
  font-family: 'ClashDisplay-Medium';
  letter-spacing: 0.015em;
}

.body-large {
  font-size: 1.25rem;
}

.bold {
  font-weight: 700;
}

*,
*::before,
*::after  {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}

.sr-only {
  border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
button,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

svg {
  display: block;
  flex-shrink: 0;
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  font-size: 1rem;
  line-height: 1.5;
}

button,
select {
  text-transform: none;
}

.link {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 14px 0;
  font-size: 1rem;
  line-height: 1;
  text-decoration: underline;
  font-family: 'ClashDisplay-Medium';
  letter-spacing: 0.015em;
}

.underline {
  text-decoration: underline;
}

.icon-large {
  width: 40px;
  height: 40px;
}

.icon-small {
  width: 20px;
  height: 20px;
}

.icon-xs {
  width: 14px;
  height: 14px;
}

.icon-dark {
  fill: var(--primary);
}

.icon-left {
  margin-right: 12px;
}

.icon-right {
  margin-left: 12px;
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.r-45 {
  transform: rotate(45deg);
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1;
  font-family: 'ClashDisplay-Semibold';
  padding: 14px 32px;
  border-radius: 50px;
  border: none;
  text-decoration: none;
}

.btn--outline,
.btn.btn--outline-dark,
.btn.btn--grey {
  padding: 15px 31px;
}

.btn.btn--small {
  padding: 16px;
}

.btn.btn--large {
  padding: 18px 32px;
}

.btn.btn--xs {
  padding: 10px 16px;
}

.btn.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn.btn--blue,
.btn.btn--green {
  color: var(--primary);
}

.btn.btn--blue:hover,
.btn.btn--green:hover {
  filter: brightness(0.8);
}

.btn.btn--green {
  background-color: var(--tertiary);
}

.btn.btn--blue {
  background-color: var(--secondary90);
}

.btn.btn--outline {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.btn.btn--outline:hover {
  background-color: white;
  color: var(--primary);
}

.btn.btn--outline-dark {
  background-color: white;
  color: var(--primary);
  border: 1px solid var(--primary);
  outline-offset: -3px;
}

.btn.btn--outline-dark:hover {
  background-color: var(--primary);
  color: white;
}

.dark-section .btn.btn--outline-dark:hover {
  border-color: white;
}

.btn.btn--grey {
  background-color: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}

.btn.btn--grey:hover {
  background-color: white;
  color: var(--primary);
}

.btn.btn--white {
  background-color: white;
  color: var(--primary);
}

.btn.btn--white:hover {
  background-color: var(--primary);
  color: white;
}

.btn.btn--red {
  background-color: var(--red);
  color: white;
}

.btn.btn--red:hover {
  filter: brightness(0.8);
}

.btn.btn--filter {
  padding: 16px;
  background-color: white;
  color: var(--primary);
  border: 1px solid var(--primary60);
  outline-offset: -3px;
}

.btn.btn--filter.active,
.btn.btn--filter:hover {
  background-color: var(--primary50);
}

.btn__info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 12px;
  font-size: 0.75rem;
  line-height: 1;
  font-family: 'ClashDisplay-SemiBold';
  letter-spacing: 0.015em;
  border-radius: 50%;
}

.btn__info--green {
  background-color: var(--tertiary);
}

.circle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border: 1px solid white;
  border-radius: 50%;
}

.label {
  position: relative;
}

.label__btn {
}

.label__btn * {
  pointer-events: none;
}

.label__btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 13px;
  padding: 4px 8px;
  gap: 4px;
  border-radius: 0px 6px 6px 0px;
  background-color: var(--warning);
  font-family: 'Roboto';
  outline-offset: 2px;
  text-align: left;
}

.label__btn::before,
.label__btn::after {
  content: '';
  position: absolute;
  right: calc(100% - 1px);
}

.label__btn::before {
  top: 0;
  bottom: 0;
  left: -13px;
  background: no-repeat url('./images/icons/discount.svg');
  background-size: 100% 100%;
}

.label__btn::after {
  width: 6px;
  height: 6px;
  top: 50%;
  background-color: white;
  border-radius: 50%;
  transform: translateY(-50%);
}

.info {
  display: flex;
  align-items: center;
  padding: 11px 12px;
  background-color: var(--primary60);
  border-radius: 8px;
  line-height: 1.5;
  font-weight: 500;
}

.info--green {
  background-color: var(--tertiary);
}

.info--red {
  background-color: var(--red);
  color: white;
}

.info-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--secondary90);
  color: var(--primary);
  border-radius: 50%;
}

.info-btn svg {
  fill: var(--primary);
}

.info-btn--border {
  border: 1px solid var(--primary);
}

.theme-light .btn--outline {
  color: var(--primary);
  border: 1px solid var(--primary);
}

.theme-light .btn--outline:hover {
  background-color: var(--primary);
  color: white;
}

.rating {
  display: flex;
  align-items: center;
  font-family: 'ClashDisplay-Semibold';
  background-color: var(--primary);
  color: white;
  padding: 2px 4px;
  border-radius: 50px;
  font-size: 0.75rem;
  letter-spacing: 0.015em;
}

.rating svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.alert,
.success,
.confirmation,
.notice {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  gap: 4px;
  color: var(--primary);
}

.alert svg,
.success svg,
.confirmation svg,
.notice svg {
  align-self: flex-start;
  width: 14px;
  height: 16px;
}

.alert {
  background-color: var(--red90);
}

.success {
  background-color: var(--tertiary);
}

.confirmation {
  background-color: var(--tertiary90);
}

.notice {
  background-color: var(--primary50);
}

.question .confirmation {
  color: var(--primary);
}

.relative {
  position: relative;
}

body,
html {
  overflow-x: clip;
}

body {
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  color: var(--primary);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
a {
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
}

img {
  max-width: 100%;
}

.cover-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pill-button {
  border: 0;
  font-family: 'ClashDisplay-Semibold';
  background-color: var(--secondary);
  color: black;
  padding: 12px 25px;
  border-radius: 50px;
  font-size: 1rem;
  line-height: 1;
}

.pill-button:disabled {
  border: 0;
  font-family: 'ClashDisplay-Semibold';
  background-color: var(--primary60);
  color: black;
  padding: 12px 25px;
  border-radius: 50px;
  font-size: 1rem;
  line-height: 1;
  cursor: default;
}

.back-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary);
  font-family: 'ClashDisplay-Semibold';
  background-color: transparent;
  color: black;
  padding: 8px 16px;
  border-radius: 50px;
}

.back-button:hover {
  background-color: var(--primary50);
}

.back-button svg {
  width: 20px;
  height: 20px;
  fill: var(--primary);
  transform: scaleX(-1);
}

.section {
  margin: 112px 0;
}

.dark-section {
  background-color: var(--primary);
  color: white;
}

.page .section:first-child {
  margin-top: 40px;
}

.page .section:last-child {
  margin-bottom: 40px;
}

.wrap {
  width: 100%;
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;
}

.wrap .wrap {
  padding: 0;
}

.wrap--md {
  max-width: 1296px;
}

.wrap--sm {
  max-width: 912px;
}

.wrap--xs {
  max-width: 752px;
}

.wrap--full-width {
  max-width: none;
}

.is-mobile .wrap--xs {
  max-width: 900px;
}

.page {
  padding: 1px 0;
}

.page__container {
  width: 100%;
}

.page-block {
  width: 47%;
}

.is-mobile .page-block,
.page-block.page-block--full {
  width: 100%;
}

.text-container ol ol,
.text-container ol ul,
.text-container ul ul,
.text-container ul ol {
  padding-left: 1em;
}

.text-container p,
.text-container ol,
.text-container ul {
  margin: 1.5rem 0;
}

.text-container li {
  margin: 1rem 0;
}

.is-mobile .text-container p,
.is-mobile .text-container ul,
.is-mobile .text-container ol {
  margin: 1.4rem 0;
}

.text-container > p:first-child,
.text-container > ol:first-child,
.text-container > ul:first-child,
.text-container > li:first-child,
.text-container > h1:first-child,
.text-container > h2:first-child,
.text-container > h3:first-child,
.text-container > h4:first-child,
.text-container > h5:first-child,
.text-container > h6:first-child,
.text-container > ol:first-child,
.text-container > ul:first-child,
.text-container > li:first-child {
  margin-top: 0;
}

.text-container > p:last-child,
.text-container > h1:last-child,
.text-container > h2:last-child,
.text-container > h3:last-child,
.text-container > h4:last-child,
.text-container > h5:last-child,
.text-container > h6:last-child,
.text-container > ol:last-child,
.text-container > ul:last-child,
.text-container > li:last-child {
  margin-bottom: 0;
}

.text-container a {
  text-decoration: underline;
  font-weight: 700;
  color: var(--secondary);
}

.text-container a:hover {
  color: var(--secondary90);
}

.text-container ul,
.is-mobile .text-container ul {
  margin-left: 18px;
  list-style: disc outside;
}

.text-container ol,
  .is-mobile .text-container ol {
  margin-left: 18px;
  list-style: decimal outside;
}

.wave {
  width: 100%
}

.wave--mobile {
  display: none;
  height: 39px;
}

.wave--desktop {
  height: 68px;
}

.page-visual {
  width: 100%;
  margin: -2px 0;
  padding-top: 20.42%;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-visual--right {
  background-position: right bottom;
}

.page-visual--hero {
  padding-top: 15%;
}

.is-mobile .page-visual {
  padding-top: 294px;
  background-size: 1440px;
}

.is-mobile .page-visual--hero {
  padding-top: 216px;
}

.is-mobile .wave--mobile {
  display: block;
}

.is-mobile .wave--desktop {
  display: none
}

.block-loader {
  background-color: var(--primary50);
  background-image: linear-gradient(89.42deg, rgba(192, 180, 163, 0) 0.25%, rgba(192, 180, 163, 0.5) 25.52%, rgba(192, 180, 163, 0) 49.5%);
  background-size: 200%;
  background-position: 100%;
  background-repeat: no-repeat;
  opacity: 0;
  animation: block-loader 1.8s infinite ease-out 0.8s,
              fadeIn 0.4s forwards 1.5s;
  border-radius: 12px;
}

.image-loader {
  background-color: var(--primary50);
  background-image: linear-gradient(89.42deg, rgba(192, 180, 163, 0) 0.25%, rgba(192, 180, 163, 0.5) 25.52%, rgba(192, 180, 163, 0) 49.5%);
  background-size: 200%;
  background-position: 100%;
  background-repeat: no-repeat;
  animation: block-loader 1.8s infinite ease-out;
  border-radius: 12px;
}

@keyframes fadeIn {
  0%{
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes block-loader {
  0% {
    background-position: 100%;
  }
  44% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}

.slider__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  border: none;
  z-index: 10;
}

.slider__arrow--prev svg {
  transform: rotate(180deg);
}

.slider__arrow svg {
  fill: var(--primary);
}

/* Theme */

.sc-theme-dark {
  color: white;
}

.sc-theme-light {
  color: var(--primary);
}

.light-background {
  background-color: var(--primary50);
}

@media only screen and (max-width: 999px) {
  .wrap {
    padding: 0 16px;
  }

  .section {
    margin: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .section {
    margin: 40px 0;
  }
}