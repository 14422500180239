.remove-account__title {
  margin-bottom: 16px;
}

.remove-account__btn-container {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.remove-account__btn-container .btn {
  flex: 1 1 50%;
}

.is-mobile .remove-account__btn-container {
  flex-direction: column;
}