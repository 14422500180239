.home-parallax {
    position: relative;
    z-index: 1;
    margin-bottom: -2px;
}

.home-parallax__body {
    padding-bottom: 19%;
}

.home-parallax__children {
    width: 100%;
    z-index: 1;
}

.home-parallax__background-container,
.home-parallax__background-layer,
.home-parallax__background-layer-snow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-parallax__background-container {
    overflow: hidden;
    z-index: -1;
}

.home-parallax__background-layer {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
}

.home-parallax__background-layer.background {
    background-size: cover;
}

.home-parallax__placeholder {
    height: 15vw;
}

.page-layout > .home-parallax {
    margin-top: auto;
}

.is-mobile .home-parallax__body {
    padding-bottom: 160px;
}

.is-mobile .home-parallax__background-layer {
    width: 110%;
}

.is-mobile .home-parallax__placeholder {
    height: 220px;
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
    .home-parallax__body {
        padding-bottom: 15%;
    }
}

@media only screen and (max-width: 767px) {
    .is-mobile .home-parallax__background-layer {
        width: 900px;
    }

    .is-mobile .home-parallax__body {
        padding-bottom: 232px;
    }
}

/* Snow animation */

.snowflake {
    position: absolute;
    top: -15px;
    background-color: white;
    border-radius: 50%;
    opacity: 0.5;
    backface-visibility: hidden;
    perspective: 1000px;
    will-change: transform;
}

@keyframes snow {
    from {
        transform: var(--from);
    }
    to {
        transform: var(--to);
    }
}
