.my-booking {
    margin-top: 40px;
}

.my-booking__nav {
    width: 100%;
    margin: 40px 0 24px;
}

.my-booking__container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    column-gap: 32px;
    margin-bottom: 80px;
}

.my-booking__container__completed {
    flex-direction: row;
}

.my-booking__header {
    width: 100%;
    margin-bottom: 40px;
}

.my-booking__label {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    background-color: var(--tertiary);
}

.my-booking__label svg {
    flex-shrink: 0;
    margin-right: 16px;
}

.my-booking__summary {
    width: 57.6%;
}

.my-booking__title,
.my-booking__subtitle {
    margin-bottom: 16px;
}

.my-booking__map,
.my-booking .check-in-info,
.my-booking .amenities,
.my-booking__info {
    margin-bottom: 40px;
}

.my-booking .gallery {
    margin-bottom: 40px;
}

.my-booking__info {
    padding: 24px;
    border: 1px solid var(--primary60);
    border-radius: 12px;
}

.my-booking__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}

.my-booking__info .my-booking__row:not(:last-of-type) {
    margin-bottom: 24px;
}

.my-booking__row--border {
    margin: 0 -24px;
    border-top: 1px solid var(--primary60);
    padding: 24px 24px 0;
}

.my-booking__column {
    display: flex;
    flex-wrap: wrap;
}

.my-booking__name {
    width: 100%;
    margin-bottom: 8px;
}

.my-booking__phone,
.my-booking__address {
    display: inline-block;
}

.my-booking__info .btn {
    flex-shrink: 0;
}

.my-booking__info-title:not(:last-child) {
    margin-bottom: 12px;
}

.my-booking__map {
    width: 35.4%;
    min-width: 300px;
}

.my-booking__map-container {
    margin-bottom: 24px;
}

.my-booking .map {
    margin-bottom: 24px;
}

.my-booking__map-address {
    margin-bottom: 20px;
}

.my-booking__contact {
    width: 100%;
}

.my-booking__support {
    margin-bottom: 40px;
}

.my-booking__payment {
    flex: 1;
}

.my-booking__payment-card {
    padding: 24px;
    border: 1px solid var(--primary70);
    border-radius: 12px;
    margin-bottom: 16px;
}

.my-booking__payment-subtitle {
    margin-bottom: 12px;
}

.my-booking__payment-link-container {
    display: flex;
    flex-wrap: wrap;
    margin: 12px -20px 0;
}

.my-booking__payment-link {
    margin: 12px 20px 0;
}

.my-booking__property {
    flex: 0 1 438px;
    width: 0;
}

.my-booking__loader {
    display: flex;
    gap: 24px 40px;
    height: 2000px;
    margin-bottom: 40px;
}

.my-booking__loader div {
    width: 100%;
    height: 100%;
}

.my-booking .search-usp {
    margin-bottom: 24px;
}

.is-mobile .my-booking__nav {
    margin: 16px 0;
}

.is-mobile .my-booking__header {
    margin-bottom: 24px;
}

.is-mobile .my-booking__label {
    align-items: flex-start;
}

.is-mobile .my-booking__container {
    flex-direction: column;
    margin-bottom: 40px;
}

.is-mobile .my-booking__summary,
.is-mobile .my-booking__map,
.is-mobile .my-booking__payment,
.is-mobile .my-booking__property {
    flex: 0 0 auto;
    width: 100%;
    margin: 0;
}

.is-mobile .my-booking__map,
.is-mobile .my-booking .toggle-button {
    margin-bottom: 24px;
}

.is-mobile .my-booking .gallery {
    margin: 0 -16px 40px;
}

.is-mobile .my-booking__info {
    padding: 24px 16px;
}

.is-mobile .my-booking__row {
    flex-direction: column;
}

.is-mobile .my-booking__row--border {
    margin: 0 -16px;
    padding: 24px 16px 0;
}

.is-mobile .my-booking__payment-card {
    padding: 20px 16px;
    margin-bottom: 24px;
}

.is-mobile .my-booking__payment-link-container {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
}

.is-mobile .my-booking__payment-link {
    margin: 12px 0 0;
}

.is-mobile .policies {
    margin-bottom: 40px;
}

.is-mobile .my-booking__loader {
    flex-direction: column;
}
