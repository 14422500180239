.location-search__input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 2px solid var(--secondary90);
  border-radius: 26px;
  margin: 16px;
}

.location-search__input-container > svg {
  margin-left: 18px;
}

.location-search__input-field {
  border: none;
  outline: none;
  width: 100%;
  padding: 12px 17px;
  font-family: "Roboto";
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 24px;
}

.location-search__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  margin-right: 18px;
  transform: rotate(45deg);
}
