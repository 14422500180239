.countries {
  margin: 40px 0;
}

.countries__title {
  margin-bottom: 40px;
}

.countries__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.countries__list {
  padding-left: 16px;
}

.countries__item {
  width: 33.33%;
  padding: 10px 5px;
  margin: 5px 0;
}

.countries__link {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.countries__list .countries__link {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.countries__link:hover {
  font-family: 'ClashDisplay-SemiBold';
}

.is-mobile .countries__title {
  margin-bottom: 24px;
}

.is-mobile .countries__item {
  width: 50%;
}
