.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    color: var(--primary);
    z-index: 100;
    transition: var(--transition);
    transition-property: background, box-shadow;
}

.nav.sc-theme-dark {
    color: white;
}

.nav__container {
    display: flex;
    align-items: center;
}

.nav.scrolled {
    color: var(--primary);
    background: rgba(255, 255, 255, 0.8);
    box-shadow: var(--shadow);
    backdrop-filter: blur(8px);
}

.nav.scrolled.nav--open {
    backdrop-filter: none;
}

.nav__search-container {
    margin: 0 auto;
}

.nav.nav--search {
    padding: 12px 0 11px;
    border-bottom: 1px solid var(--primary50);
    transition: none;
    background: white;
}

.nav.scrolled.nav--search {
    backdrop-filter: none;
    box-shadow: none;
}

.nav__logo-container {
    display: flex;
    align-items: center;
    flex: 1 1 0px;
    margin-right: auto;
}

.nav__logo {
    display: inline-block;
}

.nav__menu-container {
    flex: 1 1 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}

.nav__logo svg {
    max-width: 100%;
    height: 40px;
}

.nav__menu-list {
    display: flex;
    align-items: center;
    list-style: none;
    font-family: 'ClashDisplay-Medium';
    margin: 0 16px 0 auto;
}

.nav__menu-item {
    margin-left: 16px;
}

.nav__menu-item a {
    padding: 8px;
    border-radius: 6px;
}

.nav__menu-item a:hover,
.nav__language-switcher .custom-dropdown__btn:hover {
    background-color: rgba(56, 49, 40, 0.1);
}

.nav.sc-theme-dark .nav__menu-item a:hover,
.nav.sc-theme-dark .nav__language-switcher .custom-dropdown__btn:hover {
    background-color: rgba(246, 238, 224, 0.1);
}

.nav.scrolled .nav__menu-item a:hover,
.nav.scrolled .nav__language-switcher .custom-dropdown__btn:hover {
    background-color: rgba(56, 49, 40, 0.1);
}

.nav__user-dropdown {
    margin-left: 16px;
}

.nav__user-dropdown .custom-dropdown__btn {
    justify-content: center;
    width: 48px;
    height: 48px;
    padding: 13px;
    background-color: transparent;
    color: inherit;
    border-color: var(--primary);
}

.nav__user-dropdown .custom-dropdown__btn .custom-dropdown__label {
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.015em;
    color: var(--primary);
    font-family: 'ClashDisplay-SemiBold';
}

.nav__user-dropdown .custom-dropdown__chevron {
    margin-left: 12px;
}

.nav__user-dropdown .custom-dropdown__menu,
.nav__navigation-dropdown .custom-dropdown__menu {
    font-family: 'ClashDisplay-Medium';
    letter-spacing: 0.015em;
    font-size: 1rem;
    line-height: 1.5;
}

.nav__open {
    border-color: var(--primary);
}

.nav.sc-theme-dark .nav__open {
    border-color: white;
}

.nav.scrolled .nav__open {
    border-color: var(--primary);
}

.nav__mobile-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 16px 6px;
    background-color: var(--primary);
    z-index: 100;
}

.nav__close {
    align-self: flex-end;
    margin-right: 10px;
}

.nav__close svg {
    transform: rotate(45deg);
    color: white;
}

.nav__mobile-menu-list {
    margin-top: 22px;
    list-style: none;
    color: white;
    overflow-x: hidden;
}

.nav__mobile-menu-item {
    margin-bottom: 8px;
}

.nav__mobile-menu-item a {
    display: block;
    padding: 12px 10px;
    width: 100%;
}

.nav__language-switcher {
    display: flex;
    align-items: center;
}

.nav__language-switcher .custom-dropdown__btn {
    background: transparent;
    color: inherit;
    font-family: 'ClashDisplay-Medium';
    padding: 8px;
    border: none;
    border-radius: 6px;
}

.sc-theme-light .nav__language-switcher .custom-dropdown__btn {
    color: var(--primary);
}

.nav__language-switcher svg {
    margin-right: 8px;
}

.nav__language-switcher .custom-dropdown__chevron {
    margin: 0 0 0 8px;
}

.nav__search-btn {
    margin: auto;
    padding: 14px 24px;
}

.nav__btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav .desktop-search-form-container {
    margin: 0;
}

.nav .desktop-search-enter {
    opacity: 0;
    transform: translateY(100px);
}

.nav .desktop-search-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: var(--transition);
}

.nav .desktop-search-exit {
    opacity: 1;
    transform: translateY(0);
}

.nav .desktop-search-exit-active {
    opacity: 0;
    transform: translateY(100px);
    transition: var(--transition);
}

.nav .input-component__label-text {
    height: 0;
    opacity: 0;
    transition: var(--transition);
}

.nav .desktop-search__input.focused .input-component__label-text {
    height: 24px;
    opacity: 1;
}

.nav .desktop-search__btn {
    padding: 8px;
    margin: 6px;
    transition: var(--transition);
}

.nav .desktop-search__input.focused ~ .desktop-search__btn {
    padding: 16px;
    margin: 12px;
}

.nav .input-component__label {
    padding: 12px 24px;
}

.nav .nav__navigation-dropdown {
    margin-left: 16px;
}

.nav .nav__navigation-dropdown .custom-dropdown__btn {
    padding: 13px;
    background: transparent;
    border-color: var(--primary);
    color: inherit;
}

.nav .custom-dropdown__menu {
    top: calc(100% + 8px);
}

.nav.sc-theme-dark .nav__navigation-dropdown .custom-dropdown__btn,
.nav.sc-theme-dark .nav__user-dropdown .custom-dropdown__btn {
    border-color: white;
}

.nav.scrolled .nav__navigation-dropdown .custom-dropdown__btn,
.nav.scrolled .nav__user-dropdown .custom-dropdown__btn,
.light-background .nav .nav__navigation-dropdown .custom-dropdown__btn {
    border-color: var(--primary90);
}

.is-mobile .nav {
    min-height: 72px;
}

.is-mobile .nav.scrolled .nav__logo {
    margin-right: auto;
}

.is-mobile .nav__logo svg {
    height: 35px;
}

.is-mobile .nav__language-switcher .custom-dropdown__btn {
    padding: 6px;
}

.is-mobile .nav__open,
.is-mobile .nav__user-dropdown {
    margin-left: 8px;
}