.hero {
    text-align: center;
    margin-top: -80px;
    padding: 80px 0 0;
    background-color: white;
}

.hero--dark {
    background-color: var(--primary);
    color: white;
}

.hero__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 978px;
    margin: 0 auto;
    padding: 80px 0 0;
}

.hero__desc,
.hero__title {
    max-width: 978px;
}

.hero__desc {
    margin-top: 16px;
}

.hero .wave {
    margin-top: 80px
}

.is-mobile .hero {
    margin-top: -80px;
    padding: 80px 0 0;
}

.is-mobile .hero__desc {
    margin-top: 16px;
}

.is-mobile .hero__container {
    padding: 40px 0 0;
}

.is-mobile .hero .wave {
    margin-top: 40px;
}
