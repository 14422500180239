.gallery {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.gallery__img-container {
    position: relative;
    flex: 0 0 61.4%;
    width: 0;
    min-height: 410px;
    align-self: stretch;
    margin: 8px;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.gallery > .gallery__img-container:only-child {
    flex: 0 0 100%;
    padding-top: 46%;
}

.gallery__right {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 38.6%;
    align-items: flex-start;
    width: 0;
}

.gallery__right > .gallery__img-container:first-of-type {
    flex: 0 0 calc(100% - 16px);
    padding-top: 61%;
    min-height: auto;
}

.gallery__right > .gallery__img-container:nth-of-type(2) {
    flex: 0 0 calc(50% - 16px);
    align-self: flex-end;
    padding-top: 46.8%;
    min-height: auto;
}

.gallery__control {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex: 0 0 50%;
    margin-left: auto;
}

.gallery__right .gallery__control:only-child .gallery__img-container {
    padding-top: 123.4%;
}

.gallery__control:nth-of-type(2),
.gallery__control:nth-of-type(1) {
    flex: 0 0 100%;
}

.gallery__control:nth-of-type(2) > .gallery__img-container {
    padding-top: 46.8%;
}

.gallery__control > .gallery__img-container {
    flex: 1 1 calc(100% - 16px);
    width: calc(100% - 16px);
    padding-top: 93.6%;
    min-height: auto;
}

.gallery__open.btn.btn--outline-dark {
    position: absolute;
    bottom: 24px;
    right: 24px;
    padding: 10px 16px;
    border-color: transparent;
    z-index: 2;
}

.is-mobile .gallery {
    margin: 0 -16px;
}

.gallery__swiper,
.gallery__swiper-btn {
    --swiper-pagination-bullet-size: 10px;
    display: block;
    width: 100%;
}

.gallery__img-container-mobile {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 56.27%;
}

.gallery__arrow {
    position: absolute;
    top: 50%;
    width: 52px;
    height: 52px;
    transform: translateY(-50%);
}

.gallery__arrow--prev {
    left: 24px;
}

.gallery__arrow--next {
    right: 24px;
}

.gallery .swiper-pagination-fraction {
    bottom: 19px;
    right: 8px;
    left: auto;
    width: auto;
    padding: 4px 8px;
    color: white;
    background-color: rgba(56, 49, 40, 0.8);
    border-radius: 50px;
    font-family: 'ClashDisplay-Medium';
}

.gallery .swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: white;
    opacity: 0.8;
}

.gallery .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active-main {
    background-color: var(--secondary);
}

.is-mobile .gallery {
    border-radius: 0;
}

.is-mobile .gallery__arrow {
    width: 36px;
    height: 36px;
}

.is-mobile .gallery__arrow--prev {
    left: 8px;
}

.is-mobile .gallery__arrow--next {
    right: 8px;
}