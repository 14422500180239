.discover-image {
    width: 100%;
    color: var(--primary);
}

.discover-image__container {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

.discover-image__img-container {
    position: relative;
    flex: 0 0 calc(100% - 80px);
    padding-top: 62.47%;
}

.discover-image__img {
    border-radius: 24px;
}

.discover-image__content {
    flex: 0 0 705px;
    margin: 80px -625px 0 0;
    padding: 80px;
    background-color: var(--primary50);
    border-radius: 24px;
    z-index: 1;
}

.discover-image__title {
    margin-bottom: 40px;
}

.discover-image__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
}

.discover-image__item svg {
    margin-right: 16px;
}

@media only screen and (max-width: 1000px) {
    .discover-image__container {
        flex-direction: column;
    }

    .discover-image__content {
        flex: 0 0 auto;
        width: 612px;
        margin: -112px 0 0 0;
        padding: 40px;
    }

    .discover-image__img-container {
        flex: 0 0 auto;
        width: calc(100% - 72px);
        margin-left: auto;
        padding-top: 56.49%;
    }
}

@media only screen and (max-width: 767px) {
    .discover-image__content {
        width: calc(100% - 40px);
        margin-top: -80px;
        padding: 24px;
    }

    .discover-image__title {
        margin-bottom: 24px;
    }

    .discover-image__img-container {
        width: calc(100% - 24px);
        padding-top: 93%;
        padding-top: min(93%, 400px);
    }
}