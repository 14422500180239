.loader-container {
    position: relative;
    width: var(--size);
    height: var(--size);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
}
.loader {
    color: var(--color);
    font-size: var(--size);
    text-indent: -99999em;
    margin: 0.1em;
    width: calc(100% - 0.2em);
    height: calc(100% - 0.2em);
    box-shadow: inset 0 0 0 0.1em;
}
.loader:before,
.loader:after {
    position: absolute;
    content: '';
}
.loader:before {
    width: 0.5em;
    height: 1em;
    background: var(--bg_color);
    border-radius: 0;
    top: 0;
    left: 0;
    border-radius: 1em 0 0 1em;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
}
.loader:after {
    width: 0.5em;
    height: 1em;
    background: var(--bg_color);
    border-radius: 0;
    top: 0;
    right: 0;
    border-radius: 0 1em 1em 0;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
