.image-slider {
    position: relative;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.image-slider__slider {
    width: 100%;
    height: 100%;
}

.image-slider__slide {
    position: relative;
    padding-top: 56.25%;
}

.image-slider__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-slider__slide:first-of-type .image-slider__img {
    border-radius: 24px 0 0 24px;
}

.image-slider__slide:last-of-type .image-slider__img {
    border-radius: 0 24px 24px 0;
}

.image-slider__arrow {
    position: absolute;
    top: 50%;
    width: 52px;
    height: 52px;
    transform: translateY(-50%);
}

.image-slider__arrow--prev {
    left: 16px;
}

.image-slider__arrow--next {
    right: 16px;
}

.image-slider__number {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    color: var(--primary);
    padding: 8px 16px;
    border-radius: 50px;
    z-index: 1;
}

.image-slider__number svg {
    margin-right: 8px;
}

.image-slider .swiper-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% - 250px);
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
}

.image-slider .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    opacity: 0.8;
    background-color: white;
}

.image-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--secondary);
}

.is-mobile .image-slider__slide {
    padding-top: 56%;
}

.is-mobile .image-slider .swiper-pagination {
    width: 100%;
}

.is-mobile .image-slider .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
}