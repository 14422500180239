.user-details {
    position: relative;
}

.user-details__info {
    margin-bottom: 16px;
}

.user-details__login-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.user-details .link {
    padding: 4px 0;
    margin: 0 0 4px auto;
}

[class~=is-mobile] .user-details .link {
    margin: 0 0 8px;
}

[class~=is-mobile] .user-details__login-container {
    margin-bottom: 24px;
}

.user-details [class~=label] {
    margin-bottom: 24px;
}