.preview-bar {
    font-size: 0.8em;
    width: 100%;
    background-color: #ff7070;
    text-align: center;
    font-weight: 700;
    position: fixed;
    top: 0;
    color: black;
    z-index: 1;
}