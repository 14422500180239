.improve {
    padding: 24px 16px;
    background-color: var(--primary50);
    border-radius: 24px;
}

.improve__header {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.improve__progress {
    color: var(--primary80);
}

.improve__cta-container {
    display: flex;
    gap: 16px;
}

.improve__cta {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 23px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid var(--primary60);
}

.improve__cta.improve__cta--green {
    background-color: var(--tertiary);
    border-color: var(--tertiary);
}

.improve__cta span {
    flex: 1 1 auto;
    text-align: left;
}

.improve__cta h2 {
    margin-bottom: 4px;
    font-weight: 600;
}

.is-mobile .improve__cta-container {
    flex-direction: column;
    gap: 8px;
}