.cards-block-highlight__container {
    display: flex;
    align-items: flex-start;
}

.cards-block-highlight__title {
    margin-bottom: 40px;
}

.cards-block-highlight__cards {
    display: flex;
    flex-direction: column;
    flex: 0 0 56.33%;
    margin-left: auto;
}

.cards-block-highlight__cta {
    margin-left: auto;
}

.page__container .cards-block-highlight {
    padding: 0;
}

@media only screen and (max-width: 1000px) {
    .cards-block-highlight__container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px) {
    .cards-block-highlight__title {
        margin-bottom: 24px;
    }
}