.hero-slider {
    position: relative;
    width: 100%;
    height: 440px;
    border-radius: 24px;
    overflow: hidden;
}

.hero-slider__slider {
    width: 100%;
    height: 100%;
}

.hero-slider__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-slider__slide:first-of-type .hero-slider__img {
    border-radius: 24px 0 0 24px;
}

.hero-slider__slide:last-of-type .hero-slider__img {
    border-radius: 0 24px 24px 0;
}

.hero-slider__arrow {
    position: absolute;
    top: 50%;
    width: 52px;
    height: 52px;
    transform: translateY(-50%);
}

.hero-slider__arrow--prev {
    left: 16px;
}

.hero-slider__arrow--next {
    right: 16px;
}

.hero-slider__number {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 8px 16px;
    border-radius: 50px;
    z-index: 1;
}

.hero-slider__number svg {
    margin-right: 8px;
}

.hero-slider .swiper-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% - 250px);
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
}

.hero-slider .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    opacity: 0.8;
    background-color: white;
}

.hero-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--secondary);
}

.is-mobile .hero-slider {
    margin: 0 -16px;
    width: calc(100% + 32px);
    height: 240px;
    border-radius: 0;
}

.is-mobile .hero-slider__slide:first-of-type .hero-slider__img,
.is-mobile .hero-slider__slide:last-of-type .hero-slider__img {
    border-radius: 0;
}

.is-mobile .hero-slider .swiper-pagination {
    width: 100%;
}

.is-mobile .hero-slider .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
}