.toggle-button__item {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  font-family: 'ClashDisplay-Semibold';
  z-index: 1;
}

.toggle-button__item svg {
  margin-right: 6px;
  width: 20px;
  height: 20px;
}