.blog-hero {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    column-gap: 6%;
}

.blog-hero__header {
    width: 100%;
    padding: 6px 0;
    margin-bottom: 40px;
}

.blog-hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 47%;
}

.blog-hero__back svg {
    transform: scaleX(-1);
}

.blog-hero__title {
    margin-bottom: 24px;
}

.blog-hero__date {
    display: block;
    margin-bottom: 24px;
}

.blog-hero__list {
    margin-top: 40px;
}

.blog-hero__item {
    display: flex;
    align-items: flex-start;
}

.blog-hero__item:not(:last-of-type) {
    margin-bottom: 16px;
}

.blog-hero__item svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.blog-hero__visual {
    width: 47%;
}

.blog-hero__img-container {
    align-self: flex-start;
    position: relative;
    width: 100%;
    padding-top: 75.05%;
}

.blog-hero__img {
    border-radius: 24px;
}

.is-mobile .blog-hero {
    flex-direction: column;
    margin-top: 32px;
}

.is-mobile .blog-hero__content,
.is-mobile .blog-hero__visual {
    width: 100%;
}

.is-mobile .blog-hero__visual {
    margin-top: 24px;
}

.is-mobile .blog-hero__img-container {
    padding-top: 74.93%;
}

.is-mobile .blog-hero__header {
    margin-bottom: 32px;
}

.is-mobile .blog-hero__list {
    margin-top: 24px;
}