.recommendation {
  position: relative;
  display: flex;
  flex: 0 0 calc(100% / 3 - 8px);
  width: 0;
  background-color: white;
  flex-direction: column;
  margin: 12px 4px;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  transition: box-shadow var(--transition);
  border-radius: 15px;
  text-align: left;
}

.search__content--full .recommendation {
  flex: 0 0 calc(20% - 8px);
}

.recommendation__img-container {
  position: relative;
  margin-bottom: 8px;
  padding-top: 66.67%;
  border-radius: 12px;
  overflow: hidden;
  z-index: 0;
}

.recommendation__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  color: var(--primary);
}

.content:not(.is-mobile) .recommendation:hover,
.content:not(.is-mobile) .recommendation--hot {
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.32);
  z-index: 1;
}

.recommendation__title,
.recommendation__subtitle {
  margin-bottom: 4px;
}

.recommendation__subtitle {
  font-weight: 400;
}

.recommendation__info {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 4px;
  gap: 8px;
}

.recommendation__info .form-label {
  margin-left: auto;
  align-self: flex-end;
}

.recommendation__footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 4px;
  margin-top: auto;
}

.recommendation__price-container {
  display: flex;
  column-gap: 8px;
}

.recommendation__strikethrough-price {
  text-decoration: line-through;
}

.recommendation__info-price {
  flex-shrink: 0;
}

.recommendation__price-container--total {
  color: var(--primary90);
}

.recommendation__info-price--total {
  font-family: 'ClashDisplay-Medium';
}

.recommendation__info-total {
  white-space: nowrap;
  color: var(--primary90);
}

.recommendation__raiting-container {
  height: 50px;
}

.recommendation__rating {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: calc(100% - 24px);
  font-size: 0.75rem;
  font-family: 'ClashDisplay-SemiBold', sans-serif;
  z-index: 1;
}

.recommendation__rating svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.recommendation__rating-count {
  flex-shrink: 0;
}

.recommendation__rating-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
}

.recommendation__star-container {
  display: flex;
  padding: 5px 2px;
}

.recommendation__star-icon {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}

.recommendation__details-icon {
  align-self: flex-start;
  padding: 3px;
  margin-right: 4px;
  background-color: var(--tertiary);
  border-radius: 50%;
}

.recommendation__details-item {
  display: flex;
  align-items: center;
  border-radius: 12px;
}

.recommendation__details-item:not(:last-of-type) {
  margin-bottom: 16px;
}

.recommendation__details-item--chargers {
  padding: 8px;
  background-color: var(--tertiary90);
}

.recommendation__details-item--chargers div > div:not(:last-of-type) {
  margin-bottom: 2px;
}

.recommendation__details-item--chargers span {
  font-weight: 700;
}

.recommendation__info-item:not(:last-of-type) {
  margin-bottom: 4px;
}

.recommendation--map-container {
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.recommendation--map-container > div {
  display: flex;
  justify-content: center;
}

.recommendation--map {
  position: absolute;
  bottom: 40px;
  width: 298px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
}

.recommendation .card-image-slider {
  border-radius: 12px;
}

.recommendation .label {
  flex-shrink: 0;
  margin-left: auto;
}

@media only screen and (max-width: 499px) {
  .recommendation,
  .search__content--full .recommendation {
    flex: 0 0 100%;
    padding: 0;
  }

  .recommendation--map {
    padding: 8px;
  }

  .recommendation:not(:last-of-type) {
    margin: 12px 0 28px;
  }

  .recommendation:last-of-type {
    margin: 12px 0 0;
  }

  .recommendation__rating {
    top: 12px;
    left: 12px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 650px) {
  .search__content--full .recommendation {
    flex: 0 0 calc((100% / 2) - 8px);
  }
}

@media only screen and (min-width: 651px) and (max-width: 899px) {
  .search__content--full .recommendation {
    flex: 0 0 calc((100% / 3) - 8px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .recommendation {
    flex: 0 0 calc(100% / 2 - 8px);
  }
  .search__content--full .recommendation {
    flex: 0 0 calc((100% / 3) - 8px);
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1350px) {
  .search__content--full .recommendation {
    flex: 0 0 calc((100% / 4) - 8px);
  }
}
