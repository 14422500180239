.draft-tag {
    background: rgb(234, 245, 255);
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    color: rgb(0, 96, 150);
    font-size: 0.7em;
}

.draft-tag-card {
    position: relative;
    float: right;
}

.draft-tag-page {
    position: absolute;
    left: 50%
}