.discover {
    padding: 80px 0;
    text-align: center;
}

.discover__title {
    margin-bottom: 40px;
}

.discover__list {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.discover__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
}

.discover__item img {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 16px;
}

.discover__cta {
    margin-top: 24px;
}

.discover__cta img {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 12px;
    transform: rotate(45deg);
}

.discover .background-image-block {
    margin-top: -166px;
    z-index: -1;
}

.is-mobile .discover {
    padding: 40px 0;
}

.is-mobile .discover__title {
    padding: 0 10px;
}

.is-mobile .discover__list {
    text-align: left;
    align-items: flex-start;
}

.is-mobile .discover .background-image-block {
    margin-top: -145px;
}