.coc__header {
    display: flex;
    align-items: center;
    column-gap: 24px;
    margin-bottom: 24px;
}

.coc_img {
    align-self: flex-start;
    margin-left: auto;
}

.coc__cta {
    width: 100%;
    margin-top: 24px;
}

.is-mobile .coc__header {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 24px;
}

.is-mobile .coc_img {
    margin-left: 0;
}