.pagenotfound__block {
    position: relative;
    margin: 128px auto 18px;
    z-index: 1;
    background-color: white;
    border: 1px solid var(--primary60);
    border-radius: 24px;
    max-width: 586px;
    padding: 24px;
}

.pagenotfound__text {
    margin-bottom: 40px;
    font-family: 'ClashDisplay-Bold';
    text-align: center;
}

.pagenotfound__link {
    width: 100%;
}

.is-mobile .pagenotfound__block {
    margin: 40px auto;
}