.gallery-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 40px;
    background-color: white;
}

.gallery-modal__overflow {
    overflow-y: auto;
    height: 100%;
    margin-top: 24px;
    padding-bottom: 40px;
}

.gallery-modal__container {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}

.gallery-modal__img-btn {
    width: calc(50% - 16px);
    margin: 8px;
    border-radius: 24px;
    outline-offset: 2px;
}

.gallery-modal__img-container {
    display: block;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
    width: 100%;
    padding-top: 100%;
    pointer-events: none;
}

.gallery-modal__img-btn:nth-of-type(3n - 2) {
    width: calc(100% - 16px);
}

.gallery-modal__img-btn:nth-of-type(3n - 2) .gallery-modal__img-container {
    padding-top: 56.15%;
}

.is-mobile .gallery-modal {
    padding: 16px;
}

.is-mobile .gallery-modal__overflow {
    margin-top: 8px;
}

.is-mobile .gallery-modal__container {
    margin: -4px;
}

.is-mobile .gallery-modal__img-container {
    border-radius: 0;
    padding-top: 133.33%;
}

.is-mobile .gallery-modal__img-btn {
    margin: 4px;
    width: calc(50% - 8px);
    border-radius: 0;
}

.is-mobile .gallery-modal__img-btn:nth-of-type(3n - 2) {
    width: calc(100% - 8px);
}

.is-mobile .gallery-modal__img-btn:nth-of-type(3n - 2) .gallery-modal__img-container {
    padding-top: 75%;
}
