.guest-result {
    color: var(--primary);
}

.guest-result__item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.guest-result__left {
    flex-grow: 1;
    margin-right: 24px;
    min-width: 88px;
}

.guest-result__left--child {
    padding-left: 16px;
}

.guest-result__item--child .custom-dropdown {
    width: 144px;
}

.guest-result__item--child .custom-dropdown__btn {
    width: 100%;
    justify-content: space-between;
    padding: 11px 15px;
}

.guest-result__title {
    margin-bottom: 4px;
}
