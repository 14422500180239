.room-details .hero-slider {
    margin-bottom: 32px;
}

.room-details__header {
    display: flex;
    align-items: flex-start;
    column-gap: 16px;
    margin-bottom: 12px;
}

.room-details .room-card__title {
    font-size: 1.5rem;
    line-height: 1;
    font-family: 'ClashDisplay-Bold';
}

.room-details__header .btn {
    margin-left: auto;
}

.room-details__includes,
.room-details__policy {
    margin-bottom: 24px;
}

.room-details__includes {
    display: flex;
}

.room-details__amenities {
    display: flex;
    margin: 0 -20px 20px;
}

.room-details .amenities__all-amenity-list {
    padding: 0;
}

.is-mobile .room-details .hero-slider {
    margin-bottom: 40px;
    margin-top: -16px;
}

.is-mobile .room-details__amenities {
    flex-wrap: wrap;
}

.is-mobile .room-details .btn {
    margin-bottom: 24px;
}