.contact-form {
    width: 100%;
}

.contact-form__container {
    display: flex;
    justify-content: space-between;
}

.contact-form__desc,
.contact-form__form-container {
    flex: 0 0 47%;
    width: 0;
}

.contact-form__title {
    margin-bottom: 20px;
}

.is-mobile .contact-form__container {
    flex-direction: column;
    row-gap: 40px;
}

.is-mobile .contact-form__desc,
.is-mobile .contact-form__form-container {
    width: 100%;
}