.bookings__header,
.bookings__desc {
    margin-bottom: 24px;
}

.bookings__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.bookings .desktop-search-form {
    width: min-content;
}

.booking-item {
    position: relative;
    display: flex;
    border: 1px solid var(--primary60);
    padding: 16px 24px 16px 16px;
    border-radius: 24px;
}

.booking-item__img-container {
    position: relative;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 16px;
    border-radius: 16px;
    overflow: hidden;
    z-index: 0;
}

.booking-item__body {
    flex-grow: 1;
    align-self: center;
    margin-right: 16px;
}

.booking-item__content {
    flex-grow: 1;
    display: flex;
}

.booking-item__title {
    margin-bottom: 8px;
}

.booking-item__price {
    flex-shrink: 0;
    margin-left: auto;
}

.booking-item__cancelled {
    display: flex;
    position: absolute;
    top: 10px;
    left: -10px;
    padding: 3px 10px;
    background-color: var(--red);
    color: white;
    border-radius: 8px;
}

.booking-item__cancelled svg {
    margin-right: 6px;
}

.bookings__list:not(:last-of-type) {
    margin-bottom: 32px;
}

.bookings__item:not(:last-of-type) {
    margin-bottom: 16px;
}

.bookings__subtitle {
    margin-bottom: 16px;
}

.bookings .custom-dropdown__btn {
    padding: 13px 15px;
    line-height: 1;
    border-color: var(--primary60);
}

.bookings .custom-dropdown__btn.open,
.bookings .custom-dropdown__btn:hover {
    background-color: var(--primary50);
}

.bookings .custom-dropdown__menu {
    font-size: 1rem;
    font-family: 'ClashDisplay-Medium';
}

.is-mobile .bookings__header {
    flex-direction: column;
    align-items: flex-start;
}

.is-mobile .booking-item {
    padding: 12px;
}

.is-mobile .booking-item__content {
    flex-direction: column;
}

.is-mobile .booking-item__body {
    align-self: flex-start;
}

.is-mobile .booking-item__price {
    margin-top: 8px;
}