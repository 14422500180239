.amenities__title {
    margin-bottom: 16px;
}

.amenities__subtitle {
    margin-bottom: 8px;
}

.amenities__list-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
}

.amenities__list-container--all {
    position: relative;
    margin-bottom: 24px;
    padding-bottom: 24px;
}

.amenities__list-container--all::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    height: 1px;
    background-color: #c0b4a3;
}

.amenities__list {
    width: calc(100% / 3);
    padding: 0 16px;
}

.amenities__more svg {
    margin: 0 0 0 8px;
}

.amenities__all-amenity-list {
    width: 40%;
    margin: 0 16px;
}

.amenities__all-amenity-list ul {
    list-style: none;
}

.amenities__item {
    display: flex;
    margin-bottom: 16px;
    break-inside: avoid-column;
}

.amenities__item svg,
.amenities__all-amenity-item svg {
    margin-right: 12px;
}

.amenities__all-amenity-item svg {
    margin-top: -3px;
}

.amenities__all-amenity-item {
    position: relative;
    display: flex;
    margin-top: 16px;
    break-inside: avoid-column;
}

.amenities__item-title {
    margin-bottom: 8px;
}

.amenities__item-info {
    margin-bottom: 12px;
}

.amenities__all-amenities {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #c0b4a3;
}
/* 
.amenities__all-amenities .amenities__subtitle {
    margin-bottom: 8px;
} */

.amenities__subtitle {
    font-family: 'ClashDisplay-Medium';
}

.is-mobile .amenities__title {
    margin-bottom: 26px;
}

.is-mobile .amenities__list-container {
    margin: 0 -16px;
}

.is-mobile .amenities__list-container--all::after {
    left: 16px;
    right: 16px;
}

.is-mobile .amenities__all-amenity-list {
    min-width: 200px;
}

.is-mobile .amenities__list {
    width: 100%;
    padding: 0 16px;
    min-width: auto;
}

.is-mobile .amenities__item-info {
    margin-left: -32px;
}
