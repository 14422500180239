.gallery-images {
    width: 100%;
    height: 100%;
    padding: 40px 0;
    background-color: var(--primary);
    color: white;
}

.gallery-images__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-images__swiper {
    flex: 0 0 calc(100% - 128px);
    width: 0;
    max-width: 992px;
    margin: 0 auto;
}

.gallery-images__arrow-container {
    width: 48px;
}

.gallery-images__arrow {
    flex-shrink: 0;
}

.gallery-images__img-container {
    position: relative;
    width: 100%;
    height: calc((var(--vh, 1vh) * 100) - 256px);
}

.gallery-images__img {
    object-fit: contain;
    opacity: 0;
    transition: var(--transition);
}

.gallery-images__img.swiper-lazy-loaded {
    opacity: 1;
}

.gallery-images__pagination {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    padding: 16px 88px;
}

.gallery-images__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid white;
    border-radius: 50%;
    outline-offset: 4px;
}

.gallery-images__close {
    position: absolute;
    top: 40px;
    right: 40px;
    transform: rotate(45deg);
}

.is-mobile .gallery-images {
    padding: 80px 0 50px;
}

.is-mobile .gallery-images__container {
    height: calc((var(--vh, 1vh) * 100) - 130px);
    margin: 0 -16px;
}

.is-mobile .gallery-images__img-container {
    height: calc((var(--vh, 1vh) * 100) - 130px);
}

.is-mobile .gallery-images__swiper {
    flex-basis: 100%;
}

.is-mobile .gallery-images__pagination {
    position: absolute;
    bottom: 16px;
    right: 16px;
    padding: 0;
    margin: 0;
}

.is-mobile .gallery-images__close {
    top: 16px;
    right: 16px;
}

@media only screen and (max-height: 500px) {
    .gallery-images {
        padding: 0;
    }

    .gallery-images__pagination {
        display: none;
    }

    .gallery-images__img-container {
        height: calc(var(--vh, 1vh) * 100);
    }

    .gallery-images__close {
        top: 16px;
    }

    .is-mobile .gallery-images {
        padding: 0 80px;
    }

    .is-mobile .gallery-images__container,
    .is-mobile .gallery-images__img-container {
        height: calc(var(--vh, 1vh) * 100);
    }
}

@media only screen and (max-width: 999px) {
    .gallery-images__close {
        right: 16px;
    }
}