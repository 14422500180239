.search__container {
  display: flex;
  margin-bottom: 80px;
}

.search__content {
  position: relative;
  flex: 0 0 58%;
  padding: 0 40px;
  transition: 600ms;
}

.search__content--full {
  flex: 0 0 100%;
}

.search__map,
.loading-map {
  position: sticky;
  top: 72px;
  height: calc(100vh - 72px);
  align-self: flex-start;
  flex: 0 0 42%;
  z-index: 1;
}

.search__searchbar-container {
  margin: 24px 0 16px;
}

.search__open-container {
  padding: 16px;
  margin: 0 -16px;
  background: white;
}

.search__open-container.map-open {
  position: relative;
  top: 0;
}

.search__open {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  padding: 12px 24px;
  border: 1px solid var(--primary60);
  border-radius: 100px;
  background-color: white;
}

.search__description {
  margin-bottom: 12px;
}

.search__results {
  position: relative;
  margin: 0 -12px;
  padding-top: 24px;
}

.search__results-container {
  display: flex;
  flex-wrap: wrap;
}

.search__results-container.disabled {
  opacity: 0.3;
  user-select: none;
  pointer-events: none;
}

.search__subtitle {
  width: 100%;
  margin: 8px 12px 0;
}

.recommendation + .search__subtitle {
  margin-top: 40px;
}

.search .toggle-button {
  z-index: 1;
}

.search__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
}

.search__range {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 24px;
  right: 40px;
  width: calc(100% - 80px);
  z-index: 1;
  pointer-events: none;
  filter: drop-shadow(var(--shadow));
}

.search__range * {
  pointer-events: all;
}

.search__map-btn {
  padding: 10px 16px;
  background-color: white;
}

.search__map-btn:hover {
  color: black;
}

.search__map-btn.disabled {
  background-color: var(--primary50);
  color: var(--primary90);
}

.search__map-btn.search__map-btn--minus {
  border-radius: 40px 0 0 40px;
}

.search__map-btn.search__map-btn--plus {
  border-radius: 0 40px 40px 0;
  border-left: 1px solid var(--primary60);
  padding-left: 15px;
}

.search-map__charger-toggle .toggle{
  position: absolute;
  right: 40px;
  bottom: 22px;
  padding: 15px 80px 15px 16px;
  background-color: white;
  border-radius: 12px;
  font-weight: 700;
  box-shadow: var(--shadow);
}

.search-map__charger-toggle .toggle .toggle__checkmark::before {
  top: 8px;
  right: 8px;
}

.search-map__charger-toggle .toggle .toggle__checkmark::after {
  top: 12px;
  right: 44px;
}

.search .bold span {
  font-weight: 400;
}

.search__more {
  width: 100%;
  justify-content: center;
  margin: 24px auto 0;
}

.search__title {
  width: 100%;
  margin-top: 24px;
}

.search__header,
.search__filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.search__header-top {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.search__filter-container {
  flex: 1 1 auto;
  width: 54px;
}

.search__filter-container .custom-dropdown {
  flex: 0 0 auto;
}

.search__filter-container .custom-dropdown__btn {
  padding: 13px 15px;
  border-color: var(--primary60);
  font-family: 'ClashDisplay-Semibold';
}

.search__filter-container .custom-dropdown__btn.open,
.search__filter-container .custom-dropdown__btn:hover {
  background-color: var(--primary50);
}

.search__filter-container .custom-dropdown__menu {
  font-family: 'ClashDisplay-Medium';
}

.search__map-toggle,
.search__header .toggle-button {
  margin-left: auto;
}

.search__loading-overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.is-mobile .search__loading-overlay {
  left: -16px;
  right: -16px;
}

.search__loading-container {
  position: sticky;
  top: 50%;
  margin-bottom: 200px;
  padding: 4px;
  border-radius: 50%;
  background-color: white;
  box-shadow: var(--shadow);
}

.is-mobile .search {
  padding: 0 16px;
}

.is-mobile .search__container {
  flex-direction: column;
  margin-bottom: 40px;
}

.is-mobile .search__content {
  flex: 0 0 100%;
  padding: 0;
  min-width: auto;
  transition: none;
}

.is-mobile .search__map {
  position: relative;
  top: auto;
  flex: 0 0 100%;
  margin: 550px -16px 0;
  align-self: auto;
}

.is-mobile .search__map.hidden {
  position: absolute;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  width: 100%;
  z-index: -1;
}

.is-mobile .loading-map {
  position: relative;
  height: 660px;
  margin: -72px -16px 0;
  align-self: auto;
  border-radius: 0;
}

.is-mobile .search__range {
  top: -514px;
  right: 16px;
  width: calc(100% - 28px);
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 7px;
}

.is-mobile .search-map__charger-toggle {
  position: relative;
  right: auto;
  bottom: auto;
}

.is-mobile .search-map__charger-toggle .toggle {
  position: relative;
  right: auto;
  bottom: auto;
  padding-left: 8px;
}

.is-mobile .search__loader {
  height: 0;
}

.loading-recommendation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

.loading-recommendation__left {
  width: 32.2%;
  height: 128px;
}

.loading-recommendation__right {
  width: 64.9%;
}

.loading-recommendation__top {
  height: 14px;
  margin-bottom: 16px;
}

.loading-recommendation__bottom {
  height: 98px;
}

.recommendation-loader {
  flex: 0 0 calc(50% - 8px);
  width: 0;
  margin: 12px 4px;
  padding: 8px;
}

.search__content--full .recommendation-loader {
  flex: 0 0 calc(20% - 8px);
}

.recommendation-loader__img {
  width: 100%;
  padding-top: 64.55%;
  margin-bottom: 16px;
}

.recommendation-loader__title {
  width: 100%;
  height: 24px;
  margin-bottom: 8px;
}

.recommendation-loader__line {
  width: 60%;
  height: 18px;
  margin-bottom: 8px;
}

.recommendation-loader__footer {
  display: flex;
  margin-top: 16px;
}

.recommendation-loader__footer .recommendation-loader__title {
  width: 78px;
  margin: 0 0 0 auto;
}

.is-mobile .loading-recommendation {
  flex-direction: column;
}

.is-mobile .loading-recommendation__left {
  width: 100%;
  padding-top: 61.69%;
  height: auto;
  margin-bottom: 16px;
}

.is-mobile .loading-recommendation__right {
  width: 100%;
}

.is-mobile .search .parallax {
  margin: 0 -16px;
}

@media only screen and (max-width: 499px) {
  .search__results,
  .search__subtitle {
    margin: 0;
  }
}

@media only screen and (max-width: 650px) {
  .recommendation-loader,
  .search__content--full .recommendation-loader {
    flex: 0 0 100%;
    margin: 12px 0;
    padding: 0;
  }
}

@media only screen and (min-width: 651px) and (max-width: 899px) {
  .search__content--full .recommendation-loader {
    flex: 0 0 calc((100% / 3) - 8px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .recommendation-loader {
    flex: 0 0 calc(100% - 8px);
  }

  .search__content--full .recommendation-loader {
    flex: 0 0 calc((100% / 3) - 8px);
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1350px) {
  .search__content--full .recommendation-loader  {
    flex: 0 0 calc((100% / 4) - 8px);
  }
}

@media only screen and (min-width: 2000px) {
  .recommendation-loader {
    flex: 0 0 calc(100% / 3 - 8px);
  }
}