.rate-card {
  padding: 12px;
  background-color: white;
  border-top: 1px solid var(--primary80);
}

.rate-card:last-of-type {
  border-radius: 0 0 24px 24px;
}

.rate-card:nth-of-type(2) {
  border-top: 1px solid var(--primary50);
}


.rate-option__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 12px 0;
}

.rate-option__body {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.room-option__price {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 1 1 100%;
  width: 0;
  gap: 8px;
}

.room-option__policy {
  flex: 1 1.3 100%;
  width: 0;
}

.rate-option:not(:first-of-type) {
  border-top: 1px solid var(--primary50);
}

.rate-option__amenity-list {
  display: flex;
  flex-wrap: wrap;
}

.rate-option__amenity-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 8px;
}

.rate-option__amenity-item svg {
  align-self: flex-start;
  margin-right: 8px;
}

.rate-option__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;
}

.rate-option__footer .btn {
  padding-left: 10px;
  padding-right: 10px;
}

.rate-option__modal-btn {
  padding: 12px 16px;
}

.rate-option__price {
  flex-shrink: 0;
}

.rate-option__price__strikethrough {
  text-decoration: line-through;
}

.is-mobile .rate-card {
  flex-direction: column;
}

.is-mobile .rate-option__content {
  flex-direction: column;
  align-items: flex-start;
}


.is-mobile .rate-option__body {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}

.is-mobile .rate-option__footer {
  width: 100%;
  gap: 8px;
}

.is-mobile .rate-option__footer .btn {
  width: 100%;
}

.is-mobile .room-option__price {
  align-self: flex-end;
}

.is-mobile .room-option__price,
.is-mobile .room-option__policy {
  width: 100%;
}