@keyframes fade-in {
    from {
        opacity: 0;;
    }
    to {
        opacity: 1;
    }
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 110;
    transition: var(--transition);
    opacity: 0;
}

@keyframes slide-from-bottom {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.modal-container.slide-in {
    animation: slide-from-bottom 250ms;
}
