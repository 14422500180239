.search-block {
    width: 100%;
}

.search-block__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 40px;
    max-width: 736px;
    background-color: var(--primary50);
    border-radius: 24px;
    color: var(--primary);
}

.search-block__text {
    text-align: center;
    margin-bottom: 16px;
}

@media only screen and (max-width: 900px) {
    .search-block__content {
        align-items: stretch;
    }

    .search-block__text {
        text-align: left;
    }

    .search-block .mobile-search-btn__icon,
    .search-block .desktop-search__btn {
        margin-left: auto;
    }
}

@media only screen and (max-width: 767px) {
    .search-block__content {
        padding: 24px;
    }
}