.home-hero {
    color: var(--primary);
    margin-top: -80px;
    padding: min(11.3%, 164px) 0 min(5.4%, 78px);
}

.is-dark .home-hero {
    color: white;
}

.home-hero--dark {
    background-color: var(--primary);
    padding: 114px 0 0;
}

.home-hero__container {
    border-radius: 24px;
    background-size: cover;
    background-position: center;
}

.home-hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 343px;
    text-align: center;
}

.home-hero__title {
    margin-bottom: 32px;
}

.home-hero__desc {
    margin-bottom: 16px;
}

.home-hero .wave {
    margin-top: 122px
}

.home-hero__btn-container {
    height: 80px;
    width: 100%;
}

.desktop-search__title {
    margin-bottom: 16px;
}

.desktop-search-home-enter {
    opacity: 0;
    transform: translateY(-100px);
}

.desktop-search-home-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: var(--transition);
}

.desktop-search-home-exit {
    opacity: 1;
    transform: translateY(0);
}

.desktop-search-home-exit-active {
    opacity: 0;
    transform: translateY(-100px);
    transition: var(--transition);
}

.is-mobile .home-hero {
    margin-top: -80px;
    padding: 60px 0 40px;
}

.is-mobile .home-hero--dark {
    padding: 68px 0 0;
}

.is-mobile .home-hero .wave {
    margin-top: 20px;
}

.is-mobile .home-hero__content {
    max-width: 550px;
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .is-mobile .home-hero {
        padding: 128px 0 40px;
    }

    .is-mobile .home-hero__content {
        padding: 0 8px;
        /* text-align: left; */
        min-height: 264px;
    }

    .is-mobile .home-hero__title {
        margin-bottom: 8px;
    }
}