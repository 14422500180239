.category {
    width: 100%;
}

.category__container {
    display: flex;
    flex-direction: column;
}

.category__nav {
    display: flex;
    overflow-x: auto;
    margin: 0 -40px 15px;
    padding: 5px 40px 20px;
    width: calc(100% + 80px);
}

.category__nav li:not(:first-of-type) {
    margin-left: 3px;
}

.category__btn {
    display: flex;
    align-items: center;
    position: relative;
    color: var(--primary90);
    padding: 5px;
}

.category__btn:hover {
    color: var(--primary);
}

.category__btn.active {
    color: var(--primary);
    font-family: 'ClashDisplay-SemiBold';
}

.category__btn::before {
    content: '';
    width: 0;
    height: 3px;
    margin-right: 8px;
    background-color: var(--primary80);
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.category__btn.active::before {
    background-color: var(--primary);
}

.category__btn.active::before {
    width: 24px;
}

.category__blog-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.category__title {
    margin-bottom: 30px;
}

.category__more {
    min-width: 308px;
    margin: 24px auto 16px;
}

.category__more svg {
    transform: rotate(45deg);
}

.dark-section .category__btn {
    color: var(--primary80);
}

.dark-section .category__btn.active,
.dark-section .category__btn:hover {
    color: white;
}

.dark-section .category__btn::before {
    background-color: var(--primary80);
}

.dark-section .category__btn.active::before {
    background-color: white;
}

@media only screen and (max-width: 999px) {
    .category__nav {
        margin: 0 -16px 15px;
        padding: 5px 16px 20px;
        width: calc(100% + 32px);
    }
}