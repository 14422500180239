.latest-booking__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.latest-booking__desc {
    margin-bottom: 8px;
}

.latest-booking .desktop-search-form-container {
    margin: 0;
    width: 100%;
}

.latest-booking .desktop-search__input {
    flex: 1 1 100%;
    width: 0;
}

.latest-booking .input-component__label {
    width: 100%;
}

.latest-booking__booking {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 1px solid var(--primary60);
    border-radius: 24px;
}

.latest-booking__booking .h4 {
    margin-bottom: 8px;
}

.latest-booking .mobile-search-btn {
    width: 100%;
    max-width: 343px;
}

.latest-booking .mobile-search-btn__label {
    margin-right: auto;
}