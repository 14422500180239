.search-usp {
  padding: 24px;
  border-radius: 24px;
  background: var(--primary50) url('../../images/usp-block.svg') no-repeat bottom left;
  background-size: 100%;
  padding-bottom: 16%;
}

.search-usp__text {
  margin-bottom: 40px;
}

.search-usp__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.search-usp__item svg {
  margin-right: 16px;
}

.is-mobile .search-usp__text {
  margin-bottom: 24px;
}