.footer {
    background-color: var(--primary);
    color: white;
}

.footer__container {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    padding: 80px 0;
}

.footer__col {
    flex: 0 1 352px;
}

.footer__label {
    margin-bottom: 8px;
    line-height: 2;
}

.footer__legal {
    padding: 6px 0;
    white-space: pre-wrap;
}

.footer__link {
    display: inline-block;
    padding: 6px 0;
}

.footer__item:not(:last-of-type) {
    margin-bottom: 8px;
}

.footer__link:hover,
.footer__bottom-link:hover {
    text-decoration: underline;
}

.footer__bottom {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 18px 0;
}

.footer__bottom-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.footer__bottom-link {
    display: block;
    padding: 6px;
}

.footer__bottom-item:not(:first-of-type) {
    position: relative;
    margin-left: 26px;
}

.footer__bottom-item:not(:first-of-type)::before {
    content: '•';
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
}

.is-mobile .footer .newsletter-signup {
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .footer__container {
        flex-direction: column;
        padding: 40px 0;
    }

    .footer__col {
        flex: 0 0 auto;
    }

    .footer__col:not(:last-of-type) {
        margin-bottom: 40px;
    }

    .footer .newsletter-signup__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer .newsletter-signup__title {
        margin-bottom: 8px;
    }

    .footer__bottom-list {
        justify-content: flex-start;
        margin: 0 -6px;
    }

    .footer__bottom {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 12px;
        padding: 18px 0 24px;
    }

    .footer__legal {
        padding: 0;
    }
}