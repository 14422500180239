.datepicker__header {
    display: flex;
    padding: 16px 0;
    border-bottom: 1px solid var(--primary50);
}

.datepicker__header .toggle-button {
    margin: 0 auto;
}

.datepicker__header .toggle-button::before {
    background-color: white;
}

.datepicker__header .toggle-button__item {
    padding: 4px 16px;
}

.datepicker__date {
    margin-left: 8px;
    font-family: 'Roboto';
}

.react-datepicker {
    position: relative;
    display: inline-flex;
    gap: 8px;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 21px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--next {
    right: 12px;
}

.react-datepicker__navigation--previous {
    left: 12px;
}

.react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
}

.react-datepicker__navigation-icon::before {
    border-color: var(--primary);
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: block;
    height: 12px;
    position: absolute;
    top: 10px;
    width: 12px;
}

.react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    right: -9px;
}

.react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: -9px;
}

.react-datepicker__month-container {
    margin: 16px;
}

.react-datepicker__header {
    text-align: center;
}

.react-datepicker__current-month {
    margin: 9px 36px 18px;
    font-size: 1rem;
    line-height: 1.5;
    font-family: 'ClashDisplay-SemiBold';
    letter-spacing: 0.015em;
    text-transform: capitalize;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
}

.react-datepicker__week {
    margin: 8px 0;
}

.react-datepicker__month {
    text-align: center;
}

.react-datepicker__day {
    position: relative;
    cursor: pointer;
    border-radius: 50%;
}

.react-datepicker__day-name {
    text-transform: capitalize;
    color: var(--primary90);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 44px;
    line-height: 20px;
    text-align: center;
}

.react-datepicker__day::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 10px);
    padding-top: calc(100% - 10px);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;
}

.react-datepicker__day:hover,
.react-datepicker__day.react-datepicker__day--keyboard-selected {
    outline: 0;
}

.react-datepicker__day:focus-visible {
    z-index: 1;
}

.react-datepicker__day:hover::before,
.react-datepicker__day.react-datepicker__day--keyboard-selected::before {
    box-shadow: 0 0 0 1px var(--primary);
}

.react-datepicker__day.react-datepicker__day--selecting-range-end {
    border-radius: 0 50% 50% 0;
}

.react-datepicker__day.react-datepicker__day--selecting-range-start {
    border-radius: 50% 0 0 50%;
    background-color: var(--secondary90);
}

.react-datepicker .react-datepicker__day.react-datepicker__day--selecting-range-start:focus-visible::before {
    border-radius: 50% 0 0 50%;
    width: 100%;
    height: 100%;
    padding-top: 0;
}

.react-datepicker__day.react-datepicker__day--in-range {
    background-color: var(--primary50);
    border-radius: 0;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--in-range:focus-visible::before {
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
}

.react-datepicker__day.react-datepicker__day--selected {
    background-color: var(--secondary90);
}

.react-datepicker  .react-datepicker__day.react-datepicker__day--range-start {
    border-radius: 50% 0 0 50%;
    background-color: var(--secondary90);
}

.react-datepicker .react-datepicker__day.react-datepicker__day--range-start:focus-visible::before {
    border-radius: 50% 0 0 50%;
    width: 100%;
    height: 100%;
    padding-top: 0;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--range-end {
    border-radius: 0 50% 50% 0;
    background-color: var(--secondary90);
}

.react-datepicker .react-datepicker__day.react-datepicker__day--range-end:focus-visible::before {
    border-radius: 0 50% 50% 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
}

.react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-end) {
    border-radius: 0;
}

.react-datepicker__day--in-selecting-range {
    border-style: dashed;
    border-color: var(--primary70);
    border-width: 1px 0;
    border-radius: 0;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--outside-month,
.react-datepicker .react-datepicker__day.react-datepicker__day--disabled,
.react-datepicker .react-datepicker__day.react-datepicker__day--outside-month::before,
.react-datepicker .react-datepicker__day.react-datepicker__day--disabled::before {
    outline: none;
    border: none;
    background-color: transparent;
    box-shadow: none;

}

.react-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
    user-select: none;
    pointer-events: none;
    color: transparent;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--disabled {
    cursor: default;
    opacity: 0.4;
}

.modal-container .datepicker__header {
    margin: -24px -24px 0;
}

.modal-container .react-datepicker {
    width: 100%;
    margin: 0 -24px;
}

.modal-container .mobile-search .react-datepicker {
    margin: 0;
    flex-direction: column;
}

.mobile-search .datepicker__container {
    display: flex;
    flex-direction: column;
    height: calc(var(--vw) * 137);
    max-height: 514px;
    margin: 0 -17px;
    overflow-y: auto;
}

.mobile-search .react-datepicker__month-container {
    margin: 0;
}

.mobile-search .react-datepicker__day-name,
.mobile-search .react-datepicker__day,
.mobile-search .react-datepicker__time-name {
    width: 100%;
    height: calc(var(--vw) * 12);
    max-height: 68px;
    line-height: 20px;
}

.mobile-search .react-datepicker__day.react-datepicker__day--in-range {
    background-color: white;
}

.mobile-search .react-datepicker__day.react-datepicker__day--range-start,
.mobile-search .react-datepicker__day.react-datepicker__day--range-end  {
    background-color: var(--secondary90);
}

.mobile-search .react-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
    background-color: transparent;
}

.mobile-search .react-datepicker__navigation {
    top: 5px;
}

.datepicker__more {
    margin: 0 auto;
}

.mobile-search .react-datepicker__day.react-datepicker__day {
    box-shadow: none;
    outline: none;
}