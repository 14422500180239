.tips-carousel {
    width: 100%;
}

.tips-carousel__slider {
    padding: 24px;
    max-width: 992px;
}

.tips-carousel__card {
    display: flex;
    align-items: center;
    column-gap: 13.7%;
    padding: 64px;
    height: 100%;
    color: var(--primary);
    background-color: var(--primary50);
    border-radius: 24px;
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tips-carousel__card.single {
    max-width: 86.6%;
    padding: 40px;
    column-gap: 8.85%;
}

.tips-carousel__card.single.right {
    margin-left: auto;
}

.tips-carousel__card.right {
    flex-direction: row-reverse;
}

.tips-carousel__img-container {
    position: relative;
    padding-top: calc(100% / 3);
    width: calc(100% / 3);
    flex-shrink: 0;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.tips-carousel__card.single .tips-carousel__img-container {
    padding-top: 30.1%;
    width: 30.1%;
}

.tips-carousel .tips-carousel__slide {
    align-self: stretch;
    height: auto;
}

.tips-carousel .swiper-pagination-fraction {
    top: 48px;
    right: 48px;
    left: auto;
    bottom: auto;
    width: auto;
    font-size: 1.5rem;
    font-family: 'ClashDisplay-Semibold';
    font-weight: 600;
    line-height: 1.15;
    letter-spacing: 0.015em;
    color: var(--primary70);
}

.tips-carousel__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 16px;
    row-gap: 16px;
    margin: 16px auto;
    max-width: 992px;
    padding: 0 24px;
}

.tips-carousel__arrow--prev svg {
    transform: scaleX(-1);
}

.tips-carousel__slide.swiper-slide-active .tips-carousel__card {
    box-shadow: 24px 24px 0 var(--primary60);
}

.is-mobile .tips-carousel__card {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 48px 24px 24px;
}

.is-mobile .tips-carousel__card.single {
    padding: 24px;
    max-width: 100%;
}

.is-mobile .tips-carousel .tips-carousel__img-container {
    width: 100%;
    padding-top: 100%;
    margin-top: 24px;
}

.is-mobile .tips-carousel .swiper-pagination-fraction {
    top: 24px;
    right: 40px;
}

.is-mobile .tips-carousel__slider {
    padding: 0 16px 16px 0;
}

.is-mobile .tips-carousel__slide.swiper-slide-active .tips-carousel__card {
    box-shadow: 16px 16px 0 var(--primary60);
}

.is-mobile .tips-carousel__arrow svg {
    margin: 0;
}