.cards-block {
    width: 100%;
}

.cards-block__container {
    display: flex;
    flex-direction: column;
}

.cards-block__title {
    text-align: center;
    margin-bottom: 40px;
}

.cards-block__cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.cards-block--center .cards-block__cards {
    justify-content: center;
}

.cards-block__cta {
    margin: 24px auto 16px;
}

.cards-block__cta img {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 12px;
    transform: rotate(45deg);
}
