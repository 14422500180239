.toggle-button {
  position: relative;
  display: inline-flex;
  padding: 4px;
  border-radius: 50px;
  background-color: var(--primary50);
}

.toggle-button::before {
  content: '';
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: var(--left);
  width: var(--width);
  border-radius: 50px;
  background-color: var(--tertiary);
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}