.privacy {
    padding: 80px 0 160px;
}

.privacy__nav {
    margin: 0 -8px 24px;
}

.privacy__btn {
    margin: 0 8px 8px;
}

.privacy__title {
    margin-bottom: 60px;
}

.is-mobile .privacy {
    padding: 60px 0 160px
}

.is-mobile .privacy__title {
    margin-bottom: 40px;
}