.image-block {
    width: 100%;
}

.image-block__img-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.is-mobile .image-block__img-container {
    padding-top: 75%;
}