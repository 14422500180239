.grouped-buttons {
    display: flex;
}

.grouped-buttons__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px 0 0 24px;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    font-size: 1rem;
    height: 48px;
    width: 48px;
    color: white;
    box-shadow: none;
    outline-offset: 4px;
}

.grouped-buttons__btn:last-of-type {
    transform: scaleX(-1);
}

.grouped-buttons__btn:disabled {
    background-color: var(--primary80);
    border-color: var(--primary80);
}

.grouped-buttons__value {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top: 1px solid var(--primary60);
    border-bottom: 1px solid var(--primary60);
    font-size: 1em;
    height: 48px;
    width: 48px;
    color: var(--primary);
    text-transform: none;
}
