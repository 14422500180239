.newsletter-signup.light {
    background-color: white;
    color: var(--primary);
}

.newsletter-signup__title {
    margin-bottom: 8px;
}

.newsletter-signup__form {
    display: flex;
    width: 100%;
    max-width: 467px;
}

.newsletter-signup__email {
    flex: 1 1 100%;
    width: 0;
    font-size: 1rem;
    line-height: 1.5;
    background: white;
    padding: 12px 20px;
    border-radius: 24px 0 0 24px;
    color: var(--primary);
}

.newsletter-signup.light .newsletter-signup__email {
    padding: 13px 20px;
    border-color: var(--primary70);
    border-width: 1px 0 1px 1px;
}

.newsletter-signup__email::placeholder {
    color: var(--primary80);
}

.newsletter-signup__btn {
    flex-shrink: 0;
    padding: 14px 16px;
    border-radius: 0 24px 24px 0;
}

.is-mobile .newsletter-signup {
    padding: 40px 0;
}

.is-mobile .newsletter-signup__title {
    margin-bottom: 24px;
}

.is-mobile .newsletter-signup__form {
    max-width: 350px;
}

.is-mobile .newsletter-signup__email {
    padding: 14px 20px;
}

.is-mobile .newsletter-signup__btn {
    padding: 10px 17px;
}

.is-mobile .newsletter-signup__btn svg,
.is-mobile .newsletter-signup .loader-container {
    margin-right: 0;
}