.supplier__container {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 80px;
}

.supplier__nav {
  width: 100%;
  margin-bottom: 24px;
}

.supplier__back svg {
  transform: scaleX(-1);
}

.supplier__banner {
  margin-top: 40px;
}

.supplier__title {
  margin-bottom: 8px;
}

.supplier__banner-info {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.supplier__rating {
  margin-right: 8px;
}

.supplier__info-container {
  width: 0;
  flex: 0 1 64%;
  padding-right: 6.4%;
}

.supplier__recommendations {
  position: relative;
  margin-top: 96px;
  padding: 58px 24px 40px;
  width: 100%;
  background-color: var(--primary50);
  border-radius: 24px;
}

.supplier__recommendations-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}

.supplier__recommendations-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 32px;
}

.supplier__recommendations-filters .toggle {
  padding: 4px 76px 4px 0;
}

.supplier__recommendations-info {
  margin-bottom: 24px;
}

.supplier__recommendations-info svg {
  align-self: flex-start;
  margin-top: 2px;
}

.supplier__recommendations > .swiper {
  padding: 0 40px;
}

.supplier__swiper-nav {
  display: flex;
  align-self: flex-start;
  top: 40px;
  right: 40px;
  margin-left: auto;
  gap: 8px;
}

.supplier__arrow {
  width: 48px;
  height: 48px;
}

.supplier__subtitle {
  margin-bottom: 24px;
}

.supplier__checkin-info {
  display: flex;
  gap: 32px;
  margin-top: 40px;
}

.supplier__checkin-info > div {
  flex: 0 0 calc(50% - 16px);
}

.supplier__checkin-title {
  margin-bottom: 8px;
}

.supplier .amenities,
.supplier__description {
  margin-top: 40px;
}

.supplier__map-container {
  width: 0;
  flex: 0 0 36%;
  min-width: 300px;
}

.supplier__map {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
}

.supplier__map-label {
  position: absolute;
  top: 21px;
  left: -1px;
  padding: 16px 16px 16px 1px;
  background-color: white;
  border-radius: 0px 24px 24px 0px;
  box-shadow: var(--shadow);
  z-index: 1;
}

.supplier .search__open {
  position: absolute;
  top: -40px;
  left: 16px;
  right: 16px;
  width: auto;
}

.is-mobile .supplier__nav {
  margin-bottom: 16px;
}

.is-mobile .supplier__container {
  flex-direction: column;
  margin-bottom: 40px;
}

.is-mobile .supplier__info-container,
.is-mobile .supplier__map-container {
  width: 100%;
  padding: 0;
}

.is-mobile .supplier__banner {
  margin: 16px 0;
}

.is-mobile .supplier__banner-info {
  margin-bottom: 16px;
}

.is-mobile .supplier__recommendations {
  margin: 56px -16px 0;
  width: calc(100% + 32px);
  padding: 56px 16px 24px;
}

.is-mobile .supplier__swiper-nav {
  margin-top: 16px;
  padding: 0 16px;
  justify-content: space-between;
}

.is-mobile .supplier__recommendations-message {
  padding: 0 16px;
  margin-bottom: 16px;
}

.is-mobile .supplier__recommendations-info {
  margin-bottom: 16px;
}

.is-mobile .supplier__recommendations > .swiper {
  padding: 0 16px;
}

.is-mobile .supplier .amenities,
.is-mobile .supplier__checkin-info,
.is-mobile .supplier__description {
  margin-top: 24px;
}

.is-mobile .supplier__map-container {
  margin-top: 24px;
  min-width: auto;
}

.is-mobile .supplier__map {
  margin-bottom: 24px;
}

.loading-banner__title {
  height: 55px;
  margin-bottom: 8px;
}

.loading-banner__info {
  height: 24px;
  margin-bottom: 24px;
}

.loading-banner__gallery {
  padding-top: 46%;
  margin-bottom: 80px;
}

.loading-supplier-map__chargers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.loading-info__title,
.loading-supplier-map__chargers-block {
  width: 48%;
  height: 50px;
  margin-bottom: 24px;
}

.loading-info__rooms {
  height: 850px;
  margin-bottom: 40px;
}

.loading-info__amenities {
  height: 150px;
}

.loading-supplier-map__map {
  height: 440px;
  margin-bottom: 24px;
}

.loading-supplier-map__address {
  height: 90px;
  margin-bottom: 40px;
}

.loading-supplier-map__title {
  height: 24px;
  margin-bottom: 24px;
}

.loading-supplier-map__chargers-block {
  height: 140px;
}

.is-mobile .supplier .address {
  width: 100%;
  margin-top: 8px;
}

.is-mobile .loading-banner__info {
  margin-bottom: 16px;
}

.is-mobile .loading-banner__gallery {
  margin-bottom: 16px;
}

.is-mobile .loading-info__title,
.is-mobile .loading-supplier-map__chargers-block {
  width: 100%;
}

.is-mobile .loading-info__title{
  height: 200px;
  margin-bottom: 40px;
}

.is-mobile .loading-supplier-map__chargers {
  flex-direction: column;
}

.is-mobile .loading-info__rooms {
  height: 800px;
}

.is-mobile .loading-info__amenities {
  width: 100%;
}

.is-mobile .loading-supplier-map__address {
  display: none;
}