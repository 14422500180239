.phone-field {
    display: flex;
    width: 100%;
}

.phone-field__country-container,
.phone-field__phone-container {
    position: relative;
    flex: 1 1 50%;
    width: 0;
}

.phone-field__country-container {
    max-width: 173px;
}

.phone-field__country-container svg {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) scaleY(-1);
    user-select: none;
    pointer-events: none;
}

.phone-field__country,
.phone-field__phone {
    width: 100%;
    padding: 11px 16px;
    font-size: 1rem;
    line-height: 1.5;
    background-color: white;
}

.error .phone-field__country,
.error .phone-field__phone {
    border-color: var(--red);
    padding-top: 9px;
    padding-bottom: 9px;
}

.error .phone-field__country {
    border-width: 2px 1px 2px 2px;
}

.error .phone-field__phone {
    border-width: 2px 2px 2px 1px;
}

.phone-field__country {
    border: 1px solid var(--primary60);
    appearance: none;
    -webkit-appearance: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 24px 0 0 24px;
    color: var(--primary);
    cursor: pointer;
}

.phone-field__phone {
    border-color: var(--primary60);
    border-width: 1px 1px 1px 0;
    border-radius: 0 24px 24px 0;
}

.phone-field__phone::placeholder {
    color: var(--primary70);
}

.phone-field__phone:focus::placeholder {
    color: transparent;
}

.phone-field__code {
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;
}

.phone-field__phone-container .phone-field__code {
    left: 15px;
    color: var(--primary70);
}

.phone-field__country-container .phone-field__code {
    right: 28px;
    color: var(--primary);
}