.mobile-destination {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 100;
    overflow: hidden;
}

.mobile-destination__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid var(--primary50);
}

.mobile-destination__title {
    margin: 0 auto;
}

.mobile-destination__header-placeholder {
    width: 44px;
}

.mobile-destination__close,
.mobile-destination__back {
    padding: 12px;
}

.mobile-destination__back {
    margin-right: auto;
}

.mobile-destination__body {
    height: 100%;
    overflow-y: auto;
}