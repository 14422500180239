.profile {
    margin: 80px 0;
}

.profile__message {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 0 auto 24px;
    padding: 11px 12px;
    border-radius: 8px;
    background-color: var(--tertiary);
    font-weight: 700;
}

.profile__message__success {
    background-color: var(--tertiary);
}
.profile__message__info {
    background-color: var(--primary50);
}
.profile__message__warning {
    background-color: var(--warning);
}


.profile__title {
    margin-bottom: 24px;
}

.profile__img-container {
    position: relative;
    padding-top: 24.43%;
    margin-bottom: 16px;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.profile__checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
}

.profile__checkbox span {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: var(--primary);
    color: white;
    padding: 14px 16px;
    border-radius: 48px;
    cursor: pointer;
}

.profile__checkbox svg {
    transform: rotate(45deg);
}

.profile__checkbox input:focus-visible~span {
    outline: 2px solid var(--primary);
    outline-offset: 1px;
}

.input-container--travellers {
    margin: 40px 0;
}

.profile__groups-controls {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
}

.profile__groups-controls .custom-dropdown__btn {
    padding: 15px 31px;
    border-color: var(--primary);
    outline-offset: -3px;
}

.profile__groups-controls .custom-dropdown__btn svg {
    margin-left: 12px;
}

.profile__groups-controls .custom-dropdown__btn:hover {
    color: white;
    background-color: var(--primary);
}

.profile__groups-controls .custom-dropdown__btn:hover .custom-dropdown__label {
    color: white;
}

.profile__groups-controls .custom-dropdown__btn .custom-dropdown__label {
    font-size: 1rem;
    line-height: 1;
    font-family: 'ClashDisplay-Semibold';
    color: var(--primary);
}

.profile__travellers {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    width: 400px;
    background-color: white;
    padding: 16px;
    box-shadow: var(--shadow);
    border-radius: 24px;
    z-index: 1;
}

.profile__travellers-container {
    display: flex;
    gap: 16px;
}

.profile__travellers-container .input-container:first-of-type {
    flex: 1 1 auto;
}

.profile__travellers-container .input-container:last-of-type {
    flex: 0 1 auto;
    max-width: 50%;
}

.profile__travellers .toggle {
    padding: 0 0 56px;
    min-width: 88px;
}

.profile__travellers .toggle__label {
    line-height: 1.5;
}

.profile__travellers .toggle__checkmark::before {
    left: 0;
    bottom: 0;
    right: auto;
    top: auto;
    height: 48px;
    width: 88px;
}

.profile__travellers .toggle__checkmark::after {
    bottom: 6px;
    left: 6px;
    top: auto;
    right: auto;
    width: 36px;
    height: 36px;
}

.profile__travellers .toggle input:checked~.toggle__checkmark::after {
    transform: translateX(40px);
}

.profile__travellers-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    border-top: 1px solid var(--primary60);
    margin: -8px -16px -16px;
    padding: 16px 16px;
}

.profile__travellers-footer .link {
    padding: 8px 16px;
}

.profile__preferences-form {
    margin-top: 40px;
}

.profile__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px 7%;
    margin-top: 40px;
}

.profile__detail {
    flex: 1 1 auto;
}

.is-mobile .profile {
    margin: 40px 0;
}

.is-mobile .profile__container {
    flex-direction: column;
    margin-top: 24px;
}

.is-mobile .input-container--travellers {
    margin: 40px 0 24px;
}
