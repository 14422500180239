.groups {
    margin: 80px 0;
}

.groups .toggle-button {
    width: 100%;
    margin-bottom: 24px;
}

.groups .toggle-button__item {
    width: 50%;
    padding: 10px;
    text-align: center;
    justify-content: center;
}

.groups .toggle-button::before {
    background-color: white;
}

.groups__header {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.groups__header .btn {
    flex-shrink: 0;
}

.groups__item {
    display: flex;
    flex-wrap: wrap;
}

.groups__item:not(:last-of-type) {
    border-bottom: 1px solid var(--primary60);
}

.groups__item-btn {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 16px 0;
    text-align: left;
    gap: 8px;
}

.groups__item-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.groups__item-label {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 1 auto;
}

.groups__item-label .h4 {
    padding: 9px 0;
}

.groups__item .profile__travellers {
    position: relative;
    width: 100%;
    padding: 0;
    box-shadow: none;
    margin-bottom: 16px;
}

.groups .wrap > .link {
    display: inline-flex;
    margin-bottom: 24px;
}

.groups .mood-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.groups .mood-list__item {
    width: calc((100% - 16px) / 3);
}

.groups .mood-list__item .mood-list__btn {
    border: none;
    background-color: var(--primary50);
    border-radius: 24px;
    height: 100%;
    padding: 16px 16px 16px 44px;
}

.groups .mood-list__item.active {
    background-color: transparent;
}

.groups .mood-list__item.active .mood-list__btn {
    background-color: var(--secondary);
}

.groups .mood-list__desc {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.groups .mood-list__title {
    line-height: 1.25;
}

.groups__title{
    margin-bottom: 40px;
}

.groups .input-label {
    margin-bottom: 8px;
}

.is-mobile .groups {
    margin: 40px 0;
}

.is-mobile .groups .wrap > .link {
    margin-bottom: 16px;
}

.is-mobile .groups__title {
    margin-bottom: 24px;
}

.is-mobile .mood-list {
    flex-direction: column;
}

.is-mobile .mood-list__item {
    width: 100%;
}