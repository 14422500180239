.image-hero {
    padding: 80px 0 40px;
}

.image-hero .wrap {
    max-width: 1216px;
}

.image-hero__container {
    display: flex;
    align-items: center;
}

.image-hero__content {
    flex: 1 0 0;
}

.image-hero__img-container {
    position: relative;
    flex: 0 0 35.1%;
    padding-top: 35.1%;
    margin-left: 40px;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.image-hero__title,
.image-hero__desc {
    margin-bottom: 32px;
}

.image-hero .desktop-search-form {
    width: min-content;
}

.image-hero__desktop-search,
.image-hero__mobile-search {
    height: 80px;
}

.is-mobile .image-hero {
    padding: 40px 0 20px;
}

.is-mobile .image-hero__container {
    flex-direction: column;
}

.is-mobile .image-hero__content {
    margin: 0 8px 32px;
    text-align: center;
}

.is-mobile .image-hero__img-container {
    width: 100%;
    margin: 0;
    padding-top: 240px;
}
