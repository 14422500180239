.change-password__btn {
  width: 100%;
  margin-top: 16px;
}

.change-password__back {
  display: inline-flex;
  margin-bottom: 24px;
}

.change-password__message {
  margin-bottom: 40px;
}