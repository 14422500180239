.content-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.content-block.tip {
    max-width: 944px;
    margin-right: auto;
}

.content-block.tip.right {
    max-width: 944px;
    margin-left: auto;
    margin-right: 0;
}

.content-block.frame {
    flex-direction: row-reverse;
    max-width: 86.6%;
    padding: 40px;
    column-gap: 8.85%;
    color: var(--primary);
    background-color: var(--primary50);
    border-radius: 24px;
    margin-left: auto;
    margin-right: auto;
}

.content-block.frame.right {
    flex-direction: row;
}

.content-block.right {
    flex-direction: row-reverse;
}

.content-block__content {
    flex-shrink: 0;
    width: 49.2%;
}

.content-block.tip .content-block__content {
    width: 61.40%;
}

.content-block:not(.frame) .content-block__content:only-child {
    width: 57.75%;
    margin: 0 0 0 8.45%;
}

.content-block.tip .content-block__content:only-child,
.content-block.frame .content-block__content:only-child,
.content-block.tip.right .content-block__content:only-child,
.content-block.frame.right .content-block__content:only-child {
    width: 100%;
    margin: 0;
}

.content-block.right:not(.tip):not(.frame) .content-block__content:only-child {
    margin: 0 8.45% 0 0;
}

.content-block__media {
    flex-shrink: 0;
    width: 40.9%;
}

.content-block.tip .content-block__media {
    width: 29.82%;
}

.content-block__img-container {
    position: relative;
    width: 100%;
    padding-top: 75%;
    border-radius: 24px;
    overflow: hidden;
    z-index: 1;
}

.content-block.tip .content-block__img-container {
    padding-top: 100%;
}

.is-mobile .content-block,
.is-mobile .content-block.frame {
    flex-direction: column;
}

.is-mobile .content-block__media {
    margin-top: 24px;
}

.is-mobile .content-block__content,
.is-mobile .content-block__media,
.is-mobile .content-block.tip .content-block__content,
.is-mobile .content-block.tip .content-block__media,
.is-mobile .content-block.frame .content-block__content,
.is-mobile .content-block.frame .content-block__media,
.is-mobile .content-block:not(.frame) .content-block__content,
.is-mobile .content-block:not(.frame) .content-block__media {
    width: 100%;
}

.is-mobile .content-block .content-block__content:only-child,
.is-mobile .content-block.tip .content-block__content:only-child,
.is-mobile .content-block.frame .content-block__content:only-child,
.is-mobile .content-block:not(.tip):not(.frame) .content-block__content:only-child,
.is-mobile .content-block:not(.tip):not(.frame) .content-block__content:only-child {
    margin: 0;
}

.is-mobile .content-block.frame {
    max-width: 100%;
    padding: 24px;
}