.room-info__address {
    margin-bottom: 16px;
}

.room-info__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 8px;
}

.room-info__price-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-left: auto;
}

.room-info__discount {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.room-info__price {
    flex-shrink: 0;
}

.room-info__label {
    margin-right: auto;
}

.room-info__row:not(:last-child) {
    margin-bottom: 16px;
}

.room-info__row--border {
    margin: 0 -24px;
    padding: 24px 24px 0;
    border-top: 1px solid var(--primary60);
}

.room-info__list {
    list-style: initial;
    list-style-position: inside;
    margin-bottom: 16px;
}

.room-info__item {
    position: relative;
    margin-bottom: 8px;
    list-style: none;
    padding-left: 23px;
}

.room-info__item::before {
    content: '•';
    position: absolute;
    top: 1px;
    left: 9px;
}

.room-info__more {
    margin: 0 0 16px auto;
}

.room-info__coc {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
}

.room-info__coc-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: left;
}

.room-info__coc-btn  span::first-letter {
    text-transform: capitalize;
}

.room-info__coc-price {
    margin-left: auto;
    flex-shrink: 0;
}

.room-info .price-breakdown {
    margin-bottom: 0;
}

.room-info__discounted-price {
    text-decoration: line-through;
}

.room-info__amenity-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.room-info__amenity-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 4px 8px;
}

.room-info__amenity-item svg {
    align-self: flex-start;
    margin-right: 8px;
}

.is-mobile .room-info__header {
    flex-direction: column-reverse;
}

.is-mobile .room-info__row--border {
    margin: 0 -16px;
    padding: 24px 16px 0;
}

.is-mobile .room-info__row:not(:last-child) {
    margin-bottom: 16px;
}