.login-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.is-mobile .login-form__footer {
  margin: auto -16px 0;
  padding: 16px 16px 0;
  border-top: 1px solid var(--primary50);
}