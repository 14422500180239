@media print {
    @page {
        margin: 1cm;
    }

    body * {
        font: 12pt Georgia, 'Times New Roman', Times, serif !important;
        line-height: 1.2 !important;
        background: #fff !important;
        color: #000 !important;
        text-align: left !important;
        position: relative !important;
        display: block !important;
        width: 100% !important;
        min-height: auto !important;
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        opacity: 1 !important;
    }

    h1 {
        font-size: 24pt !important;
        margin-bottom: 12px !important;
    }

    h2 {
        font-size: 18pt !important;
        margin: 24pt 0 8pt !important;
    }

    h3,
    h4,
    h5,
    h6 {
        font-size: 15pt !important;
        margin: 10pt 0 8pt !important;
    }

    p {
        margin: 8pt 0 !important;
    }

    li {
        margin: 10pt 0 !important;
        padding-left: 10pt !important;
    }

    ul {
        list-style: circle outside !important;
    }

    .nav,
    .footer,
    .desktop-search__container,
    .cards-block,
    .btn,
    .link,
    .page-visual,
    svg,
    .discover__item-img,
    .privacy__nav,
    .tips-carousel__controls,
    .swiper-pagination,
    .search__searchbar-container,
    .search__header,
    .search__range,
    .search__map,
    .recommendation__rating,
    .gallery .swiper-slide:not(:first-of-type),
    .room-option .swiper-slide:not(:first-of-type),
    .card-image-slider .swiper-slide:not(:first-of-type),
    .hero-slider .swiper-slide:not(:first-of-type),
    .gallery__arrow,
    .card-image-slider__arrow,
    .hero-slider__arrow,
    .desktop-search__line,
    .supplier__map .map,
    .label__btn::after,
    .label__btn::before,
    .room-option__price .label div,
    .input-container,
    li::before,
    .hero-slider__number,
    .map {
        display: none !important;
    }

    .wrap {
        max-width: none;
        padding: 0;
    }

    a,
    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    table,
    pre,
    ul,
    ol {
        page-break-inside: avoid;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img {
        page-break-after: avoid;
    }

    img {
        max-width: 5cm !important;
        max-height: 3cm !important;
        margin: 16pt 0 !important;
        object-fit: cover !important;
    }

    .search__results .recommendation {
        flex-basis: calc(100% / 4);
        width: 0 !important;
        padding: 8px !important;
    }

    .search__results-container {
        display: flex !important;
        flex-wrap: wrap;
    }

    .recommendation__details-item div div {
        display: flex !important;
    }

    .recommendation__details-item div div span {
        width: auto !important;
        margin-right: 5pt !important;
    }

    .recommendation__title {
        font-size: 16pt !important;
        margin: 5px 0 !important;
    }

    .recommendation__subtitle {
        font-size: 14pt !important;
        margin: 0 !important;
    }

    .recommendation__img-container .cover-img {
        height: 3cm !important;
        width: 100% !important;
    }

    .supplier .desktop-search-form {
        top: auto !important;
    }

    .supplier__map-container {
        margin-top: 15pt !important;
    }

    .supplier__map-label  {
        top: auto !important;
        left: auto !important;
        font-size: 16pt !important;
    }

    .room-card {
        display: flex !important;
        flex-wrap: wrap;
        padding: 5pt !important;
        margin-bottom: 5pt !important;
    }

    .room-option,
    .rate-card {
        width: 100% !important;
    }

    .room-option {
        display: flex !important;
    }

    .card-image-slider {
        width: 5cm !important;
        margin-right: 5pt !important;
    }

    .rate-card {
        display: flex !important;
        flex-wrap: wrap;
        padding: 5pt !important;
        page-break-inside: avoid;
        border: solid gray !important;
        border-width: 0 0 1pt !important;
    }

    .rate-option__amenity-list,
    .rate-option {
        width: 25% !important;
        border: none !important;
    }

    .charger-info-cards__chargers {
        display: flex !important;
        flex-wrap: wrap;
    }

    .info-card {
        width: 25% !important;
        page-break-inside: avoid;
    }

    .my-booking .hero-slider {
        height: auto !important;
    }

    .info-card,
    .booking__element-container,
    .room-info__row--border,
    .my-booking__info,
    .property-info__container {
        border: none !important;
    }
}
