.info-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: var(--primary);
  padding: 16px;
  border-radius: 24px;
  outline-offset: 4px;
  overflow: hidden;
  border: 1px solid var(--tertiary);
}

.info-card--clickable {
  box-shadow: 0 2px 0 0 var(--tertiary);
}

.info-card.info-card--clickable:hover {
  cursor:pointer;
  transform: translateY(2px);
  transition: all 100ms;
  box-shadow: 0 2px 0 0 transparent;
}

.info-card__header {
  display: flex;
  align-items: center;
  min-height: 24px;
}

.info-card__footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 8px -16px -8px;
}

.info-card__title {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-card__address {
  font-style: normal;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-card__chargers {
  font-size: 0.875rem;
  line-height: 1.25;
  margin: 4px 0 auto;
}

.info-card.info-card--map {
  position: absolute;
  bottom: 16px;
  align-items: stretch;
  padding: 16px;
  border-radius: 24px;
  width: calc(100% - 32px);
  max-width: 300px;
}

.info-card .info-card__btn {
  margin: 8px 16px;
  padding: 12px 16px;
  pointer-events: none;
  background-color: transparent;
  max-width: calc(100% - 16px);
}

.info-card__details {
  display: flex;
  flex-wrap: wrap;
  margin: 0 8px;
}

.info-card__detail {
  display: flex;
  align-items: center;
  margin: 8px;
}

.info-card__detail svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  align-self: flex-start;
}

.info-card--blue,
.info-card--green {
  color: var(--primary);
}

.info-card--blue {
  background-color: var(--secondary90);
  border-color: var(--secondary90);
}

.info-card--blue.info-card--clickable {
  box-shadow: 0 2px 0 0 #01B5D4;
}

.info-card--green {
  background-color: var(--tertiary);
  box-shadow: 0 2px 0 0 #07A65A;
}

.info-card--full-width {
  flex-grow: 1;
}