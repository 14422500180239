.room-layout {
    margin-top: 24px;
}

.room-layout__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.room-layout__item:not(:last-child) {
    margin-bottom: 16px;
}

.room-layout__label {
    margin-right: 8px;
    color: var(--primary70);
}

.room-layout__label span {
    display: block;
}

.room-layout__item--child {
    justify-content: flex-end;
}

.room-layout__item--child .room-layout__label {
    margin-right: 40px;
}

.room-layout__item--child .custom-dropdown {
    width: 144px;
    flex-shrink: 0;
}

.room-layout__item--child .custom-dropdown__btn {
    width: 100%;
    justify-content: space-between;
}