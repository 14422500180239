.check-in-info__overview-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
}

.check-in-info__occupancy {
    color: var(--primary);
}

.check-in-info__rating {
    margin-left: 20px;
}

.check-in-info__rating-count {
    white-space: nowrap;
    font-family: 'Roboto';
    margin-left: 8px;
}

.check-in-info__check {
    display: flex;
    margin-top: 24px;
}

.check-in-info__check-item {
    width: 50%;
}

.check-in-info__check-title {
    display: block;
    margin-bottom: 8px;
}

.check-in-info__check-time {
    display: block;
}

.is-mobile .check-in-info__overview-header {
    flex-direction: column-reverse;
    align-items: flex-start;
}

.is-mobile .check-in-info__rating {
    margin: 0 0 20px;
}

.is-mobile .check-in-info__occupancy {
    margin-bottom: 8px;
}

.is-mobile .check-in-info__check {
    flex-wrap: wrap;
}

.is-mobile .check-in-info__check-item {
    width: 100%;
    margin-bottom: 16px;
}

.is-mobile .check-in-info__check-item:last-child {
    margin-bottom: 0;
}
