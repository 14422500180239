.question {
    background-color: var(--primary90);
}

.question:not(:last-of-type) {
    border-bottom: 2px solid white;
}

.question.open,
.question__btn:hover {
    background-color: var(--primary);
}

.question__btn {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    padding: 24px;
}

.question__btn svg {
    width: 20px;
    height: 20px;
    margin-left: 16px;
}

.question__answer {
    padding: 0 24px 24px;
}

.dark-section .question {
    background-color: var(--primary50);
    color: var(--primary);
}

.dark-section .question.open,
.dark-section .question__btn:hover {
    background-color: white;
}

.dark-section .question:not(:last-of-type) {
    border-color: var(--primary);
}