.admin-map__container {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin: 20px 0;
    position: relative;
}

.admin-map__filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 311px;
    height: 864px;
    background-color: var(--primary);
    color: white;
    border-radius: 24px;
}

.admin-map__filters-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.admin-map__filters-container::-webkit-scrollbar {
    display: none;
}

.admin-map__map-container {
    position: relative;
    
}

.admin-map__map-container .search__map-btn-container {
    position: absolute;
    margin: 10px;
    z-index: 1;
}


.admin-map__filters-title {
    margin-bottom: 16px;
}

.admin-map .input-container {
    background: transparent;
    color: inherit;
}

.admin-map .input-label {
    margin-bottom: 4px;
}

.admin-map__filters .toggle {
    padding: 0 16px 36px 0;
    margin-bottom: 16px;
}

.admin-map__filters .toggle__checkmark::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
}

.admin-map__filters .toggle__checkmark::after {
    top: auto;
    right: auto;
    bottom: 4px;
    left: 4px;
}

.admin-map__filters-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    padding: 24px;
    margin-top: auto;
    border-top: 1px solid var(--primary90);
}

.admin-map__content {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 0 1 100%;
}

.admin-map__map-container {
    width: 100%;
}

.admin-map__card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 324px;
    width: 0;
    height: 796px;
    margin-left: -324px;
    background-color: var(--primary50);
    border-radius: 24px;
    z-index: 1;
}

.admin-map__card-header {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 24px 0 8px;
}

.admin-map__card-label {
    margin-left: auto;
    padding: 4px 8px;
    font-size: 0.875rem;
    line-height: 1;
    font-family: 'ClashDisplay-SemiBold';
    border-radius: 24px;
}

.admin-map__card-label--green {
    background-color: var(--tertiary);
    color: var(--primary);
}

.admin-map__card-label--red {
    background-color: var(--red);
    color: white;
}

.admin-map__info-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 0 24px 24px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.admin-map__info-container::-webkit-scrollbar {
    display: none;
}

.admin-map__info-email {
    display: flex;
    align-items: flex-end;
    gap: 4px;
}

.admin-map__info-email .input-container {
    margin: 0;
    flex-grow: 1;
}

.admin-map__info-label {
    display: block;
    margin-bottom: 4px;
}

.admin-map__info-note-container {
    margin-top: 8px;
}

.admin-map__info-note-container .btn {
    padding: 10px 16px;
    background-color: transparent;
}

.admin-map__info-note {
    width: 100%;
    border: 1px solid var(--primary60);
    border-radius: 16px;
    padding: 12px 16px 10px;
}

.admin-map__info-note:not(:focus) {
    background-color: transparent;
}

.admin-map__info-note:placeholder-shown {
    background-color: white;
}

.admin-map__card ul {
    list-style-position: outside;
    list-style-type: disc;
    margin-left: 1.3em;
}

.admin-map__card-footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    padding: 24px;
    margin-top: auto;
    border-top: 1px solid var(--primary60);
}

.admin-map__card-footer > .btn {
    width: 100%;
}

.admin-map__card-footer > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
}

.admin-map__loader {
    margin: 10px 0 0 auto;
}

.admin-map__content .info-card {
    width: calc(100% - 24px);
}

.admin-map .toggle-button {
    width: auto;
    margin-bottom: 16px;
}

.admin-map .toggle-button::before {
    background-color: white;
}

.admin-map .toggle-button__item {
    padding: 10px 24px;
    max-width: calc((var(--vw) * 100 - 40px) / 3);
}

.admin-map__toggle-numb {
    min-width: 1.25rem;
    padding: 4px;
    margin-left: 8px;
    border-radius: 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    font-family: 'ClashDisplay-SemiBold';
}

.admin-map__toggle-numb--grey {
    color: white;
    background-color: var(--primary90);
}

.admin-map__toggle-numb--red {
    color: white;
    background-color: var(--red);
}

.admin-map__toggle-numb--green {
    color: var(--primary);
    background-color: var(--tertiary);
}

.is-mobile .admin-map__container {
    flex-direction: column;
}

.is-mobile .admin-map__filters {
    width: 100%;
}

.is-mobile .admin-map__content {
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.is-mobile .admin-map__card {
    width: 100%;
    margin: 0;
}

.is-mobile .admin-map .toggle-button {
    margin: 0;
}

.is-mobile .admin-map__loader {
    margin: 0;
}

.is-mobile .admin-map .toggle-button__item {
    padding: 10px;
}