.page-loader {
    position: relative;
}

.page-loader__container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) translateY(-50px) scale(0.3);
    padding: 5px;
    z-index: 110;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    animation: page-loader 1.5s forwards;
    opacity: 0;
}

@keyframes page-loader {
    0% {
        transform: translate(-50%, -50%) translateY(-50px) scale(0.3);
        opacity: 0;
    }
    20% {
        transform: translate(-50%, -50%) translateY(-50px) scale(1);
        opacity: 1;
    }
    80% {
        transform: translate(-50%, -50%) translateY(-50px) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) translateY(-50px) scale(0.3);
        opacity: 0;
    }
}