.card-image-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 1;
}

.card-image-slider .card-image-slider__slider {
    width: 100%;
    height: 100%;
    --swiper-preloader-color: var(--primary);
}

.card-image-slider__slide {
    position: relative;
    padding-top: 75%;
}

.card-image-slider__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition);
}

.card-image-slider__img.swiper-lazy {
    opacity: 0;
}

.card-image-slider__img.swiper-lazy-loaded {
    opacity: 1;
}

.card-image-slider__arrow {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 36px;
    transform: translateY(-50%);
    opacity: 0;
    transition: var(--transition);
}

.content:not(.is-mobile) .card-image-slider:hover .card-image-slider__arrow,
.content:not(.is-mobile) .card-image-slider .card-image-slider__arrow:focus-visible {
    opacity: 1;
}

.card-image-slider__arrow--prev {
    left: 12px;
}

.card-image-slider__arrow--next {
    right: 12px;
}

.card-image-slider .swiper-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% - 32px);
    left: 50%;
    bottom: 14px;
    transform: translateX(-50%);
}

.card-image-slider .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 0.8;
    background-color: white;
}

.card-image-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--secondary);
}

.card-image-slider .swiper-lazy-preloader {
    width: 32px;
    height: 32px;
}

.is-mobile .card-image-slider .swiper-pagination {
    width: 100%;
}

.is-mobile .card-image-slider__slide {
    padding-top: 239px;
}