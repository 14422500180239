.booking__nav {
    width: 100%;
    margin: 40px 0;
}

.booking__back svg {
    transform: scaleX(-1);
}

.booking__container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 6.6%;
    margin-bottom: 80px;
}

.booking__element-container {
    padding: 23px;
    border: 1px solid var(--primary60);
    border-radius: 24px;
    margin-bottom: 16px;
}

.booking__details {
    /* flex: 0 1 698px; */
    flex: 1;
    width: 0;
}

.booking__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.booking__info {
    margin-left: auto;
}

.booking__error {
    margin-top: 15px;
}

.booking__header .booking__subtitle {
    margin-bottom: 0;
}

.booking__title {
    margin-bottom: 40px;
}

.booking__subtitle {
    margin-bottom: 16px;
}

.booking__price {
    flex: 0 1 438px;
    width: 0;
}

.booking__radio-container {
    margin: -6px -12px 16px;
}

.booking__radio-container:only-child {
    margin: -6px -12px;
}

.booking__radio {
    display: inline-flex;
    margin: 6px 12px;
}

.booking__btn {
    width: 100%;
}

.booking .toggle {
    padding: 12px 88px 12px 0;
}

.booking .toggle__checkmark::before {
    top: 8px;
}

.booking .toggle__checkmark::after {
    top: 12px;
}

.booking__element-container  .input-container:last-child {
    margin-bottom: 0;
}

.booking .user-message {
    margin-left: auto;
}

.booking__user {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
    padding: 16px;
    background-color: var(--primary50);
    border-radius: 16px;
}

.booking__user-header {
    display: flex;
    align-items: flex-start;
    gap: 4px;
}

.booking .search-usp {
    margin-top: 24px;
}

.is-mobile .booking__nav {
    margin: 16px 0;
}

.is-mobile .booking__title {
    margin-bottom: 24px;
}

.is-mobile .booking__element-container {
    padding: 16px;
    margin-bottom: 24px;
}

.is-mobile .booking__container {
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
}

.is-mobile .booking__details {
    flex: 0 0 100%;
    margin: 0;
}

.booking .newsletter-signup {
    padding: 0;
    margin-top: 24px;
}

.booking .newsletter-signup .wrap {
    padding: 0;
}

.is-mobile .booking__details,
.is-mobile .booking__price {
    width: 100%;
}

.booking__testmode {
    display: inline-block;
    margin-top: 8px;
    font-size: 0.8em;
    padding: 10px;
    background: var(--primary);
    color: white;
    border-radius: 20px;
}

.loading-form__title {
    height: 37px;
    margin-bottom: 24px;
}

.loading-form__row {
    display: flex;
    column-gap: 20px;
}

.loading-form__label {
    height: 16px;
    margin-bottom: 12px;
}

.loading-form__field {
    width: 100%;
}

.loading-form__input {
    height: 48px;
    margin-bottom: 20px;
}

.loading-form__trip {
    width: 50%;
    height: 32px;
    margin-bottom: 20px;
}

.loading-form__terms {
    height: 32px;
    margin-bottom: 20px;
}

.loading-form__btn {
    width: 50%;
    height: 48px;
}

.loading-message__title {
    height: 36px;
    margin-bottom: 24px;
}

.loading-message__desc {
    height: 72px;
    margin-bottom: 24px;
}

.loading-message__form {
    height: 52px;
}
