.price-breakdown {
    margin-bottom: 20px;
}

.price-breakdown__list,
.price-breakdown__list ul {
    list-style: none;
    font-size: 1rem;
    line-height: 1.8;
    width: 100%;
}

.price-breakdown__date {
    padding-left: 12px;
}

.price-breakdown__btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    text-align: left;
    background: transparent;
    font-size: 1rem;
    line-height: 1.8;
    border: none;
}

.price-breakdown__btn span {
    display: flex;
    align-items: center;
}

.price-breakdown__btn span svg {
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-left: 6px;
}

.price-breakdown__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.price-breakdown__item--dropdown {
    flex-wrap: wrap;
}

.price-breakdown__price {
    width: 50%;
    margin-left: 12px;
    text-align: right;
}

.price-breakdown-container {
    width: 100%;
}
