.location-search__item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  min-width: 250px;
}

.location-search__item .form-label {
  display: block;
  width: 100%;
  margin-bottom: 4px;
  line-height: 1.25rem;
}

.location-search__item.selected {
  text-decoration: underline;
}

.location-search__item-name {
  margin-left: 8px;
}

.location-search__item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.mobile-destination .location-search__item {
  padding: 16px;
}

.mobile-destination .location-search__item:not(:last-of-type) {
  border-bottom: 1px solid var(--primary50);
}

.suggestions-container .location-search__item {
  margin-bottom: 16px;
}

.suggestions-container .location-search__item--my-location {
  margin: 0 -24px;
  width: calc(100% + 48px);
  padding: 16px 24px;
  border-top: 1px solid var(--primary50);
}

.suggestions-container .location-search__item--my-location:only-child {
  margin: -16px -24px 0;
  border-top: none;
}