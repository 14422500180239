.faq-block {
    width: 100%;
}

.faq-block__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 16px;
}

.faq-block__container.reverse {
    flex-direction: row-reverse;
}

.faq-block__content {
    flex: 0 1 39.6%;
}

.faq-block__questions {
    flex: 0 1 49.1%;
    color: white;
    border-radius: 24px;
    overflow: hidden;
}

.faq-block__title {
    margin-bottom: 40px;
}

.faq-block .content-block {
    align-items: flex-start;
}

.is-mobile .faq-block__container {
    flex-direction: column;
}

.is-mobile .faq-block__content,
.is-mobile .faq-block__questions {
    width: 100%;
}

.is-mobile .faq-block__link {
    margin: 24px auto 0;
}
