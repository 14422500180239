.mobile-search-btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px;
    background-color: white;
    border-radius: 100px;
    box-shadow: var(--shadow);
}

.mobile-search-btn__label {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 10px;
    color: var(--primary80);
}

.mobile-search-btn__label .form-label {
    color: var(--primary);
}

.mobile-search-btn__icon {
    background-color: var(--secondary90);
    padding: 16px;
    margin-left: 14px;
    border-radius: 50%;
}