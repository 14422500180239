.room-card {
  width: 100%;
}

.room-option {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 12px;
  background-color: white;
  border-radius: 24px;
}

.room-option__with-rates {
  border-radius: 24px 24px 0 0;
}

.room-option__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 16px;
  align-self: stretch;
}

.room-option__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
}

.room-option__btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.room-card:not(:last-of-type) {
  margin-bottom: 16px;
}


.room-card .card-image-slider {
  flex-shrink: 0;
  width: 200px;
  border-radius: 16px;
}

.is-mobile .room-option,
.is-mobile .rate-card,
.is-mobile .room-card {
  flex-direction: column;
}

.is-mobile .room-card .card-image-slider {
  width: 100%;
}

.is-mobile .room-option__body {
  margin: 16px 0 0 0;
}

.is-mobile .room-option__btn-container {
  margin: 4px -12px 0;
  padding: 12px 12px 0;
  border-top: 1px solid var(--primary50);
}

.is-mobile .room-option__btn-container .btn {
  width: 100%;
}

.room-option__discount-dialog {
  text-align: right;
}