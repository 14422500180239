.property-showcase {
    width: 100%;
}

.property-showcase__title {
    margin-bottom: 32px;
    text-align: center;
}

.property-showcase__container {
    margin: 0 -8px;
    display: flex;
    flex-wrap: wrap;
}

.property-showcase__container.locked,
.property-showcase__container.locked .swiper-wrapper {
    justify-content: center;
}

.property-showcase__item {
    width: calc(100% / 5);
    height: auto;
    padding: 8px;
}

.property-showcase__item--placeholder .block-loader {
    min-height: 100%;
    padding-top: calc(80% + 163px);
    opacity: 1;
    animation: block-loader 1.8s infinite ease-out;
}

.property-showcase__card {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.property-showcase__card:hover .property-showcase__card-link {
    background-color: white;
    color: var(--primary);
}

.property-showcase__card-img-container {
    position: relative;
    padding-top: 80%;
}

.property-showcase__card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
}

.property-showcase__card-title,
.property-showcase__card-address {
    margin-bottom: 8px;
}

.property-showcase__card-address {
    color: var(--primary70);
    font-style: normal;
}

.dark-section .property-showcase__card-address {
    color: var(--primary90);
}

.property-showcase__card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 16px;
    background-color: var(--primary);
    color: white;
}

.dark-section .property-showcase__card-content {
    background-color: var(--primary50);
    color: var(--primary);
}

.btn.property-showcase__card-link {
    margin-top: auto;
    background-color: transparent;
}

.dark-section .property-showcase__card-link {
    color: var(--primary);
    border-color: var(--primary);
}

.dark-section .property-showcase__card:hover .property-showcase__card-link {
    background-color: var(--primary);
    color: white;
}

.is-mobile .property-showcase__container {
    margin: 0 -16px;
}

.is-mobile .property-showcase__item {
    min-width: 320px;
}

.is-mobile .property-showcase .swiper-slide:first-of-type {
    margin-left: 8px;
}

.is-mobile .property-showcase .swiper-slide:last-of-type {
    margin-right: 8px;
}

@media only screen and (max-width: 1000px) {
    .property-showcase__title {
        text-align: left;
    }

    .property-showcase__item {
        width: calc(100% / 3);
    }
}

@media only screen and (max-width: 767px) {
    .property-showcase__item {
        min-width: 248px;
    }
}
