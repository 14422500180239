.search-filters {
    position: relative;
    z-index: 2;
}

.search-filters__dropdown {
    position: absolute;
    top: calc(100% + 18px);
    left: 0;
    width: 400px;
    max-width: calc(100vw - 32px);
    background-color: white;
    border-radius: 24px;
    box-shadow: var(--shadow);
    overflow: hidden;
    transform: translateY(6px);
    animation: fadeFromBottom 250ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: 1;
}

@keyframes fadeFromBottom {
    0% {
        opacity: 0;
        transform: translateY(6px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.search-filters__dropdown.visible {
    transform: translateY(0);
    opacity: 1;
}

.search-filters__list {
    padding: 24px;
    max-height: 320px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.search-filters__list::-webkit-scrollbar {
    display: none;
}

.search-filters__item {
    margin-bottom: 16px;
}

.search-filters__footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    padding: 16px;
    box-shadow: var(--shadow);;
}