.card-highlight {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    outline-offset: 4px;
    border-radius: 24px;
    overflow: hidden;
}

.card-highlight:hover .card-highlight__title {
    text-decoration: underline;
}

.card-highlight.highlight {
    flex: 0 0 40.38%;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0;
}

.card-highlight__img-container {
    position: relative;
    align-self: flex-start;
    flex-shrink: 0;
    width: 176px;
    height: 176px;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}

.card-highlight.highlight .card-highlight__img-container {
    width: 100%;
    padding-top: 80%;
    border-radius: 0;
}

.card-highlight__content {
    padding: 10px 24px;
}

.card-highlight.highlight .card-highlight__content {
    padding: 16px;
    background-color: var(--primary);
    color: white;
}

.card-highlight__title,
.card-highlight__desc {
    margin-bottom: 8px;
}

.card-highlight__desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.card-highlight.highlight .card-highlight__desc {
    -webkit-line-clamp: 2;
}

.dark-section .card-highlight.highlight .card-highlight__content {
    background-color: var(--primary50);
    color: var(--primary);
}

@media only screen and (max-width: 1000px) {
    .card-highlight,
    .card-highlight.card-highlight {
        width: 100%;
    }

    .card-highlight.highlight {
        flex: 0 0 auto;
        flex-direction: row;
        margin-bottom: 24px;
    }

    .card-highlight.highlight .card-highlight__img-container {
        width: 176px;
        padding-top: 0;
        border-radius: 24px;
    }

    .card-highlight.highlight .card-highlight__content {
        padding: 10px 24px;
        background-color: transparent;
        color: var(--primary);
    }

    .dark-section .card-highlight.highlight .card-highlight__content {
        background-color: transparent;
        color: white;
    }
}

@media only screen and (max-width: 767px) {
    .card-highlight,
    .card-highlight.highlight {
        flex-direction: column;
        margin-bottom: 8px;
    }

    .card-highlight__img-container,
    .card-highlight.highlight .card-highlight__img-container {
        width: 100%;
        height: auto;
        padding-top: 75%;
    }

    .card-highlight__content,
    .card-highlight.highlight .card-highlight__content {
        padding: 16px 0;
    }
}