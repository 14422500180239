.charger-info-cards__chargers {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px 8px;
}

.charger-info-cards__title {
    margin-bottom: 16px;
}

.charger-info-cards__subtitle {
    width: calc(100% - 24px);
    margin: 0 12px 8px;
}

.info-card {
    width: calc(50% - 24px);
    margin: 0 12px 16px;
}

.is-mobile .charger-info-cards__chargers {
    margin-bottom: 8px;
}

.is-mobile .charger-info-cards__chargers .info-card {
    margin: 0 12px 16px;
    width: calc(100% - 24px);
}
